@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
/* Mixins */
/* Variables */
:root {
  --title-font-family: "DM Sans", sans-serif;
  --body-font-family: "DM Sans", sans-serif;
  --icon-font-family: "Font Awesome 6 Pro";
  --icon-font-family2: "Flaticon";
  --container-width: 1460px;
  --divder-space: 200px;
  --headings-color: #222222;
  --body-text-color: #6b7177;
}

/* Typography Styles */
html,
body {
  scroll-behavior: auto !important;
}

body {
  background-color: #ffffff;
  font-family: var(--body-font-family);
  font-size: 15px;
  font-weight: 400;
  color: var(--body-text-color);
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

a,
.btn {
  color: var(--headings-color);
  text-decoration: none;
  outline: none;
  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
a:hover,
.btn:hover {
  color: #5bbb7b;
}
a:active,
a:focus,
a:hover,
a:visited,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited {
  text-decoration: none;
  outline: none;
}

button,
.btn {
  -webkit-transition: all ease 0.4s;
  -moz-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

iframe {
  border: none;
  width: 100%;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font-family);
  color: var(--headings-color);
  line-height: 1.3125;
}

.h1,
h1,
.h2,
h2,
.h3,
h3 {
  font-weight: 700;
}

.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-weight: 500;
}

p {
  margin: 0 0 15px 0;
  color: var(--body-text-color);
  line-height: 1.85;
  font-weight: 400;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1 {
  font-size: 45px;
}

.h2,
h2 {
  font-size: 32px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 20px;
}

.h5,
h5 {
  font-size: 17px;
}

.h6,
h6 {
  font-size: 15px;
}

li {
  list-style-type: none;
}

@media (max-width: 575.98px) {
  .h2,
  h2 {
    font-size: 20px;
  }
}
/*	Header Styles  */
.login-info {
  font-size: 15px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header-top-home11 {
  background-color: #cce8ca;
  position: relative;
}

.header-top-home12 {
  background-color: #eafac1;
  position: relative;
}

.ht-close-icon {
  color: var(--headings-color);
  position: absolute;
  right: 30px;
  top: 10px;
}

/*	All Type Of Footer Styles  */
.footer-style1 {
  background-color: var(--headings-color);
}
.footer-style1.at-home5 {
  background-color: #f1fcfa;
}
.footer-style1.at-home6 {
  background-color: #48576e;
}
.footer-style1.at-home7,
.footer-style1.at-home8 {
  background-color: #ffffff;
}
.footer-style1.at-home10 {
  border-radius: 16px 16px 0 0;
}
.footer-style1.at-home11 {
  background-color: #1d6362;
}

.home2-footer-radius {
  border-radius: 0 90px 0 0;
}

.footer-widget {
  position: relative;
}

.app-widget {
  position: relative;
}
.app-widget .app-info {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px 30px 10px 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.app-widget .app-info .flex-shrink-0 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.app-widget .app-info:hover {
  background-color: #5bbb7b;
}
.app-widget .app-info.light-style {
  background-color: #222222;
}
.app-widget .app-info.light-style:hover {
  background-color: #5bbb7b;
}
.app-widget .app-info.light-style:hover .app-text {
  color: #ffffff;
}
.app-widget.at-home6 .app-info {
  background-color: #1f4b3f;
  border-radius: 8px;
  font-size: 14px;
  padding: 8px 20px 10px;
}
.app-widget.at-home6 .app-info .flex-shrink-0 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-right: 20px;
}
.app-widget.at-home6 .app-info .flex-grow-1 {
  padding-left: 20px;
}
.app-widget.at-home6 .app-info .app-text {
  color: #ffffff;
}
.app-widget.at-home7 .app-info {
  border-radius: 0;
}
.app-widget.at-home8 .app-info {
  background-color: #f1fcfa;
}
.app-widget.at-home9 .app-info {
  padding: 7px 30px;
}
.app-widget.at-home12 .app-info {
  background-color: #222222;
  border-radius: 120px;
  font-size: 14px;
}
.app-widget.at-home12 .app-info .flex-shrink-0 {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-right: 20px;
}
.app-widget.at-home12 .app-info .flex-grow-1 {
  padding-left: 20px;
}
.app-widget.at-home12 .app-info .app-text {
  color: #ffffff;
}

.mailchimp-style1 {
  position: relative;
}
.mailchimp-style1 .form-control {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  height: 60px;
  outline: none;
  padding-left: 20px;
}
.mailchimp-style1 .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  font-weight: 400;
}
.mailchimp-style1 .form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.mailchimp-style1 button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  position: absolute;
  right: 25px;
  top: 15px;
}
.mailchimp-style1.white-version .form-control {
  background-color: #ffffff;
}
.mailchimp-style1.white-version .form-control::placeholder {
  color: var(--headings-color);
  font-size: 14px;
  font-weight: 400;
}
.mailchimp-style1.white-version button {
  font-size: 15px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.mailchimp-style1.at-home4 {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
}
.mailchimp-style1.at-home4 .form-control {
  height: 60px;
  color: var(--headings-color);
}
.mailchimp-style1.at-home4 .form-control::placeholder {
  color: var(--headings-color);
}
.mailchimp-style1.at-home4 button {
  background-color: transparent;
  color: var(--headings-color);
}
.mailchimp-style1.at-home7 {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}
.mailchimp-style1.at-home7 .form-control {
  border-radius: 4px;
  color: rgba(34, 34, 34, 0.7);
  height: 60px;
}
.mailchimp-style1.at-home7 .form-control::placeholder {
  color: rgba(34, 34, 34, 0.7);
}
.mailchimp-style1.at-home7 button {
  color: #222222;
  display: block;
  height: 40px;
  line-height: 35px;
  right: 20px;
  top: 10px;
  width: 40px;
}
.mailchimp-style1.at-home8 .form-control {
  background-color: #f1fcfa;
  color: var(--headings-color);
}
.mailchimp-style1.at-home8 .form-control::placeholder {
  color: var(--headings-color);
}
.mailchimp-style1.at-home8 button {
  color: var(--headings-color);
}
.mailchimp-style1.at-home9 .form-control {
  border-radius: 60px;
  height: 70px;
  line-height: 70px;
}
.mailchimp-style1.at-home9 button {
  color: #5bbb7b;
  top: 20px;
}
.mailchimp-style1.at-home11 .form-control {
  background-color: #ffffff;
  color: var(--headings-color);
  border-radius: 16px;
}
.mailchimp-style1.at-home11 .form-control::placeholder {
  color: var(--headings-color);
  font-size: 14px;
  font-weight: 400;
}
.mailchimp-style1.at-home11 button {
  font-size: 15px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}

.mailchimp-style2 {
  position: relative;
}
.mailchimp-style2 .form-control {
  background-color: #ffffff;
  border: none;
  border-radius: 12px;
  color: var(--headings-color);
  font-size: 14px;
  font-weight: 400;
  height: 70px;
  padding-left: 30px;
}
.mailchimp-style2 .form-control:placeholder {
  color: var(--headings-color);
  font-size: 14px;
  font-weight: 400;
}
.mailchimp-style2 .form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.mailchimp-style2 button {
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.link-style1 a {
  color: rgba(255, 255, 255, 0.7);
  display: block;
  font-family: var(--title-font-family);
  line-height: 35px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.link-style1 a:hover {
  color: #ffffff;
  text-decoration: underline;
}
.link-style1.light-style a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.link-style1.at-home8 a {
  color: rgba(34, 34, 34, 0.7);
}
.link-style1.at-home9 a:hover {
  color: #5bbb7b;
}
.link-style1.at-home11 a {
  color: #ffffff;
}
.link-style1.at-home11 a:hover {
  color: #5bbb7b;
}

.footer_bottom_right_btns .bootstrap-select .btn,
.footer_bottom_right_btns .bootstrap-select .btn-light {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  line-height: 25px;
}
.footer_bottom_right_btns .bootstrap-select .btn:focus,
.footer_bottom_right_btns .bootstrap-select .btn-light:focus {
  outline: none !important;
  outline-offset: initial;
}
.footer_bottom_right_btns .bootstrap-select .dropdown-item.active,
.footer_bottom_right_btns .bootstrap-select .dropdown-item:active {
  background-color: #f1fcfa;
  color: #5bbb7b;
}
.footer_bottom_right_btns .bootstrap-select .dropdown-item.active span,
.footer_bottom_right_btns .bootstrap-select .dropdown-item:active span {
  color: #5bbb7b;
}
.footer_bottom_right_btns .bootstrap-select .dropdown-item span {
  color: var(--headings-color);
}
.footer_bottom_right_btns.at-home8
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn,
.footer_bottom_right_btns.at-home8
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn-light {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  color: var(--headings-color);
  height: 40px;
  line-height: 30px;
  padding: 5px 25px;
}
.footer_bottom_right_btns.at-home9
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn,
.footer_bottom_right_btns.at-home9
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn-light {
  border-radius: 60px;
  height: 40px;
  line-height: 30px;
  padding: 5px 25px;
}

/*	All styles about breadcumb  */
.breadcumb-section {
  padding: 20px 0;
}

.breadcumb-section2 {
  align-items: center;
  /* background-image: url(./images/background/about-page-bg.png); */
  background-size: cover;
  background-position: center;
  display: flex;
  height: 450px;
}

.breadcumb-section3 {
  align-items: center;
  /* background-image: url(./images/background/compare-bg.jpg); */
  background-size: cover;
  background-position: center;
  display: flex;
  height: 450px;
}
@media (max-width: 991.98px) {
  .breadcumb-section3 {
    height: 250px;
    position: relative;
  }
}

.breadcumb-style1 {
  position: relative;
}
.breadcumb-style1 .title {
  letter-spacing: 0.02em;
}
.breadcumb-style1 .breadcumb-list {
  position: relative;
}
.breadcumb-style1 .breadcumb-list a {
  color: var(--body-text-color);
  display: inline-block;
  font-size: 14px;
  position: relative;
  margin-right: 25px;
}
.breadcumb-style1 .breadcumb-list a:after {
  content: "/";
  font-size: 14px;
  position: absolute;
  right: -20px;
}
.breadcumb-style1 .breadcumb-list a:last-child {
  color: var(--headings-color);
}
.breadcumb-style1 .breadcumb-list a:last-child:after {
  display: none;
}

/*  Dashboard Style Code Here  */
.dashboard_header {
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dashboard_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
}

.header_right_widgets {
  position: relative;
}
.header_right_widgets .notif {
  background-color: #f7f7f7;
  border-radius: 50%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  width: 44px;
}
.header_right_widgets a {
  display: block;
  width: 44px;
}
.header_right_widgets li:last-child a {
  height: auto;
  line-height: initial;
  margin: 0;
  padding: 0 0 0 5px;
  width: auto;
}

.user_setting {
  position: relative;
}
.user_setting .dropdown-menu {
  padding: 0;
}
.user_setting .dropdown-menu.show {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 20px 30px;
  width: 330px;
  transform: translate(-272px, 60px) !important;
}
.user_setting .dropdown-menu .dropdown-item {
  border-radius: 4px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  overflow: hidden;
  font-size: 17px;
  padding: 15.5px 30px;
  -webkit-transition: all 0.3s ease 0s ease;
  -moz-transition: all 0.3s ease 0s ease;
  -ms-transition: all 0.3s ease 0s ease;
  -o-transition: all 0.3s ease 0s ease;
  transition: all 0.3s ease 0s ease;
}

.dashboard_dd_menu_list .dropdown-toggle:after {
  display: none;
}
.dashboard_dd_menu_list .dropdown-menu {
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  width: 360px;
}
.dashboard_dd_menu_list .dboard_notific_dd .img-2 {
  width: 50px;
}
.dashboard_dd_menu_list .dboard_notific_dd p {
  line-height: 24px;
}

.user_setting .dropdown-menu .dropdown-item.active,
.user_setting .dropdown-menu .dropdown-item.active span,
.user_setting .dropdown-menu .dropdown-item:hover,
.user_setting .dropdown-menu .dropdown-item:hover span {
  background-color: #222222;
  color: #ffffff;
}

.dashboard_sidebar_list .sidebar_list_item a:hover,
.dashboard_sidebar_list .sidebar_list_item a:active,
.dashboard_sidebar_list .sidebar_list_item a:focus,
.dashboard_sidebar_list .sidebar_list_item a.-is-active {
  background-color: #222222;
  color: #ffffff;
}

.dashboard_sidebar_list .sidebar_list_item a {
  align-items: center;
  display: flex;
  border-radius: 0 4px 4px 0;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  padding: 14.5px 20px;
  -webkit-transition: all 0.3s ease 0s ease;
  -moz-transition: all 0.3s ease 0s ease;
  -ms-transition: all 0.3s ease 0s ease;
  -o-transition: all 0.3s ease 0s ease;
  transition: all 0.3s ease 0s ease;
}

.dashboard_sidebar_list .sidebar_list_item a i {
  font-size: 20px;
}

.dashboard {
  display: flex;
}
.dashboard.dsh_board_sidebar_hidden .dashboard__sidebar {
  transform: translateX(-100%);
}
@media (max-width: 991.98px) {
  .dashboard.dsh_board_sidebar_hidden .dashboard__sidebar {
    transform: translateX(0);
  }
}
.dashboard.dsh_board_sidebar_hidden .dashboard__main {
  padding-left: 0;
}
@media (max-width: 991.98px) {
  .dashboard.dsh_board_sidebar_hidden .dashboard__main:after {
    background-color: rgba(4, 30, 66, 0.5);
    content: "";
    bottom: 0;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 0;
    pointer-events: auto;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
.dashboard .dashboard__sidebar {
  will-change: transform;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 9;
}
@media (max-width: 991.98px) {
  .dashboard .dashboard__sidebar {
    transform: translateX(-100%);
  }
}
.dashboard .dashboard__main {
  will-change: padding-left;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dashboard__sidebar {
  background-color: #ffffff;
  flex-shrink: 0;
  height: calc(100% - 90px);
  left: 0;
  margin-top: 80px;
  overflow-y: scroll;
  padding: 30px 30px 30px 0;
  padding-bottom: 40px;
  position: fixed;
  width: 300px;
}
.dashboard__sidebar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.dashboard__sidebar::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 4px;
}

.dashboard .dashboard__main {
  will-change: padding-left;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.dashboard__main {
  margin-top: 85px;
  padding-left: 300px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .dashboard__main {
    margin-top: 0px;
  }
}

.dashboard__content {
  border-radius: 4px;
  padding: 60px 60px 20px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .dashboard__content {
    padding: 40px 30px;
  }
}
@media (max-width: 1199.98px) {
  .dashboard__content.property-page {
    padding: 60px 30px 20px;
  }
}
@media (max-width: 1399.98px) {
  .dashboard__content.message-page {
    padding: 50px 30px;
  }
}
@media (max-width: 1440px) {
  .dashboard__content {
    padding: 60px 15px 20px;
  }
}

.dashboard_title_area {
  position: relative;
}

.statistics_funfact {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 32px 50px 32px 30px;
  position: relative;
}
.statistics_funfact .icon {
  color: #1f4b3f;
  font-size: 40px;
  position: relative;
  z-index: 0;
}
.statistics_funfact .icon:before {
  background-color: #fbf7ed;
  border-radius: 50%;
  bottom: -10px;
  content: "";
  height: 40px;
  position: absolute;
  right: -20px;
  width: 40px;
  z-index: -1;
}
.statistics_funfact .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
}

.application_statics,
.dashboard_product_list,
.dashboard_setting_box {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 30px;
  padding: 30px 30px 25px 0;
  position: relative;
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
}

.dashboard-timeline-label {
  position: relative;
}

.dashboard-timeline-label .timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.dashboard-timeline-label .child-timeline-label {
  color: var(--headings-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  width: 55px;
  flex-shrink: 0;
  position: relative;
}

.dashboard-timeline-label:before {
  background-color: #e9e9e9;
  border-radius: 8px;
  bottom: 0;
  content: "";
  left: 52px;
  position: absolute;
  top: 20px;
  width: 3px;
}

.dashboard-timeline-label .timeline-badge {
  color: #663259;
  border-radius: 100%;
  font-size: 16px;
  justify-content: center;
  margin-left: -7px;
  margin-top: -3px;
  position: relative;
  z-index: 1;
}

.dashboard-timeline-label .timeline-badge.color3 {
  color: #f64e60;
}

.dashboard-timeline-label .timeline-badge.color4 {
  color: #3699ff;
}

.dashboard-timeline-label .timeline-badge.color5 {
  color: #ef6327;
}

.dashboard-timeline-label .timeline-badge.color6 {
  color: #8950fc;
}

.dashboard-timeline-label .timeline-item .ra_pcontent .title {
  color: var(--headings-color);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.dashboard-timeline-label .timeline-item .ra_pcontent .subtitle {
  color: var(--headings-color);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.pending-style {
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 6px;
}
.pending-style.style1 {
  background-color: #fff4de;
  color: #ffa800;
}
.pending-style.style2 {
  background-color: #f1faff;
  color: #00a3ff;
}
.pending-style.style3 {
  background-color: #fff5f8;
  color: #f1416c;
}
.pending-style.style4 {
  background-color: var(--body-text-color);
  color: #ffffff;
}
.pending-style.style5 {
  background-color: #ffede8;
  color: #222222;
}
.pending-style.style6 {
  background-color: #f1faff;
  color: #00a3ff;
}

.table-action {
  background-color: #ffede8;
  border-radius: 5px;
  padding: 11px 20px;
}

.dashboard-img-service {
  position: relative;
}
.dashboard-img-service .listing-style1:hover {
  box-shadow: none;
}
.dashboard-img-service .listing-style1:hover .img-2 {
  transform: scale(1);
}
.dashboard-img-service .listing-style1.list-style .list-thumb {
  width: 122px;
}
.dashboard-img-service .list-title {
  line-height: 28px;
}

.profile-box .tag-delt {
  background-color: #ffede8;
  border-radius: 4px;
  color: var(--headings-color);
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
}
.profile-box .upload-btn {
  background-color: #fbf7ed;
  border-radius: 4px;
  color: #1f4b3f;
  display: block;
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  padding: 6px 30px;
}

.upload-img {
  background-color: #ffede8;
  border: 1px dashed #1f4b3f;
  border-radius: 4px;
  color: #1f4b3f;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  padding: 30px 80px;
}

.del-edit {
  position: absolute;
  right: 0;
}
@media (max-width: 375px) {
  .del-edit {
    margin-bottom: 15px;
    position: relative;
  }
}
.del-edit .icon {
  background-color: #ffede8;
  border-radius: 4px;
  color: #1f4b3f;
  display: block;
  height: 40px;
  line-height: 45px;
  text-align: center;
  width: 40px;
}

/* DashBoard Message Styles */
.message_container {
  background-color: #ffffff;
  border-radius: 4px;
  height: 90vh;
  overflow: hidden;
  position: relative;
}
.message_container .user_heading {
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  padding: 30px;
  position: relative;
}
.message_container .user_heading img {
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.message_container .user_heading .preview {
  color: #697488;
  font-size: 13px;
  font-family: var(--title-font-family);
  font-weight: 400;
  margin-bottom: 0;
}
.message_container .user_heading .name {
  font-size: 14px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.message_container .message_input {
  background-color: #f9fafc;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.message_container .message_input input.form-control {
  color: #626974;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top: 1px solid #f3f5f6;
  height: 100px;
  padding: 20px 30px;
  width: 100%;
}
.message_container .message_input button.btn {
  flex: 1;
  height: 50px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.inbox_chatting_box {
  height: auto;
  margin-right: 10px;
  max-height: 470px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.inbox_chatting_box::-webkit-scrollbar {
  background: transparent;
  border-radius: 10px;
  padding-right: 10px;
  width: 4px;
}
.inbox_chatting_box::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 10px;
}
.inbox_chatting_box .chatting_content {
  display: inline-block;
  padding: 15px 30px 0 30px;
  position: relative;
  width: 100%;
}
.inbox_chatting_box .chatting_content li {
  display: block;
  padding: 25px 0 5px;
  margin-bottom: 0;
  clear: both;
}
.inbox_chatting_box .chatting_content li p {
  background-color: #f1fcfa;
  border-radius: 8px;
  color: var(--headings-color);
  font-size: 14px;
  padding: 15px 20px;
  max-width: 456px;
}
.inbox_chatting_box .chatting_content li.reply {
  margin: -15px 0;
}
.inbox_chatting_box .chatting_content li.reply p {
  background-color: rgba(91, 187, 123, 0.05);
  border-radius: 8px;
  color: #5bbb7b;
  max-width: 450px;
  padding: 15px 30px;
  text-align: right;
}
.inbox_chatting_box .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.inbox_chatting_box small {
  color: #697488;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
}

.inbox_user_list {
  display: inline-block;
  padding: 30px 10px 25px 30px;
  width: 100%;
}
.inbox_user_list .iu_heading {
  padding-bottom: 25px;
}
.inbox_user_list .preview {
  font-size: 13px;
  font-family: var(--title-font-family);
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
}
.inbox_user_list .m_notif {
  background-color: #1967d2;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 8px;
  font-weight: bold;
  line-height: 15px;
  height: 16px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 33px;
  width: 16px;
}
@media (max-width: 575.98px) {
  .inbox_user_list .m_notif {
    position: relative;
    right: auto;
    top: 0;
  }
}
.inbox_user_list .m_notif.online {
  background-color: #34a853;
}
.inbox_user_list .m_notif.away {
  background-color: #f9ab00;
}
.inbox_user_list .m_notif.busy {
  background-color: #e74c3c;
}

.chat-member-list {
  height: 620px;
  overflow-y: scroll;
}
.chat-member-list::-webkit-scrollbar {
  background: transparent;
  border-radius: 10px;
  padding-right: 10px;
  width: 4px;
}
.chat-member-list::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 10px;
  height: 250px;
}
.chat-member-list img {
  width: 50px;
}
.chat-member-list .list-item {
  padding: 15px 0;
}

.chat_user_search {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.chat_user_search button {
  background-color: #f7f7f7;
  border-radius: 0;
  border: none;
  color: var(--headings-color);
  height: 50px;
  line-height: 45px;
  width: 50px;
}
.chat_user_search input.form-control {
  background-color: #f7f7f7;
  border: none;
  border-radius: 0;
  height: 50px;
  margin-right: 0 !important;
  position: absolute;
  left: 50px;
  padding-left: 0;
  padding-right: 0;
  right: 30px;
  width: 100%;
}

.iul_notific small {
  display: block;
  font-size: 13px;
  font-family: var(--title-font-family);
  font-weight: 400;
  position: absolute;
  right: 10px;
  top: 10px;
}
@media (max-width: 575.98px) {
  .iul_notific small {
    position: relative;
    right: auto;
    top: 0;
  }
}

/*	Messages Styles  */
/*	Listings Styles  */
.listing-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.listing-style1 .list-thumb {
  overflow: hidden;
  position: relative;
}
.listing-style1 img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.listing-style1 .listing-fav {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  color: var(--headings-color);
  border-radius: 50%;
  height: 36px;
  line-height: 36px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  width: 36px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.listing-style1 .list-content {
  padding: 25px 30px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .listing-style1 .list-content {
    padding: 25px 20px;
  }
}
@media (max-width: 340px) {
  .listing-style1 .list-content {
    padding: 25px 10px;
  }
}
.listing-style1 .list-title {
  margin-bottom: 15px;
}
.listing-style1 .list-title a {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.listing-style1 .list-title a:hover {
  text-decoration: underline;
  text-decoration-color: #5bbb7b;
}
.listing-style1 .online-badge {
  background-color: #5bbb7b;
  border-radius: 50%;
  outline: 1px solid #ffffff;
  height: 7px;
  position: absolute;
  right: 0;
  width: 7px;
}
.listing-style1 hr {
  background-color: #e9e9e9;
  opacity: 1;
}
.listing-style1:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.listing-style1 .list-agent {
  position: absolute;
  right: 30px;
  top: -40px;
}
@media (max-width: 479px) {
  .listing-style1 .list-agent {
    position: relative;
    right: auto;
    top: auto;
    margin-bottom: 10px;
  }
}
.listing-style1 .tag-del {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 50%;
  color: #1f4b3f;
  font-size: 14px;
  height: 36px;
  line-height: 40px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 36px;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.listing-style1:hover .list-thumb img {
  transform: scale(1.1) rotate(-1deg);
}
.listing-style1:hover .listing-thumbIn-slider:before {
  opacity: 1;
}
.listing-style1.style2 {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border: none;
}
.listing-style1.style3 {
  border-radius: 16px;
}
.listing-style1.style4 {
  box-shadow: none;
}
.listing-style1.style4 .list-thumb {
  border-radius: 4px;
  overflow: hidden;
  margin: 10px;
}
.listing-style1.style4 .list-content {
  padding-top: 0;
}
.listing-style1.style5 {
  box-shadow: none;
  border: none;
}
.listing-style1.style5 .list-thumb {
  border-radius: 4px;
  overflow: hidden;
}
.listing-style1.style5 .list-content {
  padding-top: 10px;
}
@media (max-width: 1366px) {
  .listing-style1.list-style .list-thumb {
    width: 45%;
  }
}
@media (max-width: 1199.98px) {
  .listing-style1.list-style .list-thumb {
    width: auto;
  }
}
.listing-style1.list-style .list-content,
.listing-style1.listi-style2 .list-content {
  padding: 20px;
}
.listing-style1.list-style .listing-fav,
.listing-style1.listi-style2 .listing-fav {
  border: 1px solid #e9e9e9;
  right: 10px;
  top: -10px;
}
@media (max-width: 1366px) {
  .listing-style1.list-style .listing-fav,
  .listing-style1.listi-style2 .listing-fav {
    top: 10px;
  }
}
.listing-style1.list-style .listing-thumbIn-slider,
.listing-style1.listi-style2 .listing-thumbIn-slider {
  max-width: 329px;
}
@media (max-width: 1199.98px) {
  .listing-style1.list-style .listing-thumbIn-slider,
  .listing-style1.listi-style2 .listing-thumbIn-slider {
    max-width: initial;
  }
}
.listing-style1.listi-style2 .listing-fav {
  top: 10px;
}
.listing-style1.style8 {
  box-shadow: none;
}
.listing-style1.style8 .list-thumb {
  border-radius: 0;
  overflow: hidden;
}
.listing-style1.style8 .list-title {
  line-height: 28px;
}
.listing-style1.style8 .list-content {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}
.listing-style1.listing-type {
  border-radius: 4px;
  display: flex;
}
@media (max-width: 767.98px) {
  .listing-style1.listing-type {
    display: block;
  }
}
.listing-style1.listing-type .list-content {
  padding: 30px;
}
.listing-style1.listing-type .list-text2 {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-size: 13px;
  font-weight: normal;
}
.listing-style1.listing-type img {
  height: 100%;
}
.listing-style1.style12 {
  border-radius: 4px;
}
.listing-style1.sidebar-style1 {
  box-shadow: none;
}
.listing-style1.sidebar-style1 .list-thumb {
  border-radius: 8px;
}
.listing-style1.mini-style {
  border-radius: 4px;
  -webkit-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -moz-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -o-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  left: 40px;
  max-width: 305px;
  position: absolute;
  top: -223px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .listing-style1.mini-style {
    position: relative;
    top: 0;
  }
}
@media (max-width: 425px) {
  .listing-style1.mini-style {
    left: 0;
  }
}
.listing-style1.mini-style .btn {
  padding: 0;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.listing-style1.mini-style .btn i {
  margin-left: 10px;
  transform: rotate(-30deg);
}
.listing-style1.mini-style .list-content {
  padding: 30px;
}
.listing-style1.at-home9-hero .list-content {
  padding: 35px 20px 20px;
}
.listing-style1.dashboard-style {
  box-shadow: none;
}
.listing-style1.dashboard-style .list-thumb {
  border-radius: 4px;
  height: 90px;
  max-width: 110px;
}
.listing-style1.dashboard-style img {
  border-radius: 4px;
}
.listing-style1.dashboard-style .list-text {
  margin-bottom: 0px;
}

.tag-del {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 50%;
  color: #1f4b3f;
  font-size: 14px;
  height: 36px;
  line-height: 40px;
  padding: 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 36px;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.listing-thumbIn-slider:before {
  background: linear-gradient(
    180deg,
    rgba(34, 34, 34, 0) 51.22%,
    rgba(34, 34, 34, 0.6) 100%
  );
  bottom: 0;
  content: "";
  height: 80%;
  left: 0;
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.apartment-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
.apartment-style1 .apartment-content {
  padding: 20px;
}

.apartment-style2 {
  position: relative;
}
.apartment-style2 .apartment-content {
  bottom: 0;
  left: 0;
  position: absolute;
  padding-top: 40px;
  right: 0;
  top: 0;
}

.pagination_page_count {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}

.page_control_shorting {
  position: relative;
}
.page_control_shorting
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: auto;
}
.page_control_shorting .dropdown-item.active,
.page_control_shorting .dropdown-item:focus {
  background-color: rgba(91, 187, 123, 0.07);
  color: var(--headings-color);
}
.page_control_shorting .pcs_dropdown span {
  color: #697488;
  margin-right: -8px;
}
.page_control_shorting .btn.btn-light {
  background-color: transparent;
  border: none;
  height: auto;
  width: auto;
  outline: none !important;
}
.page_control_shorting .btn.btn-light:focus {
  box-shadow: none;
  outline: none !important;
}
.page_control_shorting a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.page_control_shorting a:hover {
  font-weight: 500;
  text-decoration: underline;
}

.half_map_area_content {
  height: 850px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 15px 20px;
}
.half_map_area_content::-webkit-scrollbar {
  background: transparent;
  border-radius: 3px;
  padding-right: 10px;
  width: 5px;
}
.half_map_area_content::-webkit-scrollbar-thumb {
  background-color: #f7f7f7;
  border-radius: 3px;
  height: 200px;
}

.map-canvas.half_style {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .map-canvas.half_style {
    height: 400px;
    position: relative;
  }
}

/* Property Signle CSS */
.property-action .icon {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.property-action .icon:hover {
  border: 1px solid #222222;
  color: #222222;
}
.property-action .price {
  font-size: 26px;
  letter-spacing: 0.02em;
}
.property-action .space {
  letter-spacing: 0.02em;
}
.property-action.dark-version .icon {
  color: #ffffff;
}
.property-action.dark-version .icon:hover {
  background-color: #ffffff;
  color: var(--headings-color);
}

.sp-img-content {
  position: relative;
}
.sp-img-content .preview-img-1 {
  border-radius: 12px 0 0 12px;
  height: 558px;
}
@media (max-width: 1199.98px) {
  .sp-img-content .preview-img-1 {
    height: 430px;
  }
}
@media (max-width: 991.98px) {
  .sp-img-content .preview-img-1 {
    border-radius: 0;
    height: auto;
  }
}
@media (max-width: 1199.98px) {
  .sp-img-content .preview-img-1.sp-v3 {
    height: auto;
  }
}
.sp-img-content.at-sp-v10 .preview-img-1 {
  height: 680px;
}
@media (max-width: 991.98px) {
  .sp-img-content.at-sp-v10 .preview-img-1 {
    height: auto;
  }
}
.sp-img-content.at-sp-v10 .preview-img-2 {
  height: 220px;
}
@media (max-width: 991.98px) {
  .sp-img-content.at-sp-v10 .preview-img-2 {
    height: auto;
  }
}
.sp-img-content .preview-img-3 {
  border-radius: 0 12px 0 0;
}
@media (max-width: 991.98px) {
  .sp-img-content .preview-img-3 {
    border-radius: 0;
  }
}
.sp-img-content .preview-img-5 {
  border-radius: 0 0 12px 0;
}
@media (max-width: 991.98px) {
  .sp-img-content .preview-img-5 {
    border-radius: 0;
  }
}
.sp-img-content .all-tag {
  background-color: #ffffff;
  border-radius: 8px;
  bottom: 20px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  display: block;
  padding: 7px 20px;
  position: absolute;
  right: 20px;
}
.sp-img-content .all-tag.style2 {
  border-radius: 50%;
  height: 65px;
  line-height: 55px;
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 40%;
  width: 65px;
}
.sp-img-content .sp-img {
  display: block;
  overflow: hidden;
  position: relative;
}
.sp-img-content .sp-img img {
  height: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sp-img-content .sp-img:hover img {
  transform: scale(1.1) rotate(-1deg);
}

.overview-element {
  position: relative;
}
.overview-element .icon {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  color: var(--headings-color);
  display: block;
  font-size: 17px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}
.overview-element.dark-version .icon {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.overview-element.dark-version .icon:hover {
  background-color: #ffffff;
  color: var(--headings-color);
}

.property_video {
  /* background-image: url(./images/listings/listing-single-6.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
  height: 350px;
  position: relative;
}
.property_video .video_popup_btn {
  background-color: #ffffff;
  border-radius: 50%;
  color: var(--headings-color);
  display: block;
  height: 54px;
  line-height: 54px;
  outline: 1px solid #ffffff;
  outline-offset: 15px;
  text-align: center;
  width: 54px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.property_video .video_popup_btn:hover {
  outline-offset: 7px;
}

.nearby {
  position: relative;
}
.nearby .rating {
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  height: 44px;
  line-height: 44px;
  outline: 3px solid #c4c640;
  text-align: center;
  width: 44px;
}
.nearby .rating.style2 {
  outline: 3px solid #eb6753;
}
.nearby .blog-single-review {
  top: 13px;
}
@media (max-width: 767.98px) {
  .nearby .blog-single-review {
    top: 0;
  }
}

.walkscore {
  position: relative;
}
.walkscore .icon {
  background-color: rgba(91, 187, 123, 0.07);
  border-radius: 50%;
  color: #5bbb7b;
  display: block;
  font-size: 30px;
  height: 70px;
  line-height: 80px;
  text-align: center;
  width: 70px;
}

.calculator-chart-percent {
  border-radius: 12px;
  height: 12px;
  overflow: hidden;
}
.calculator-chart-percent .principal-interest-st,
.calculator-chart-percent .property-tax-st,
.calculator-chart-percent .home-insurance-st {
  height: 12px;
}
.calculator-chart-percent .principal-interest-st {
  background-color: #82ddd0;
  width: 56.2748%;
}
.calculator-chart-percent .property-tax-st {
  background-color: #80a1cc;
  width: 32.7942%;
}
.calculator-chart-percent .home-insurance-st {
  background-color: #f5dd86;
  width: 10.931%;
}

.list-result-calculator li {
  width: calc(50% - 30px);
  margin-bottom: 15px;
}
.list-result-calculator li:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #82ddd0;
  margin-right: 12px;
}
@media (max-width: 767.98px) {
  .list-result-calculator li {
    width: auto;
  }
}
.list-result-calculator .name-result + span {
  color: var(--headings-color);
  margin-left: auto;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
}

.sp-v5-property-details {
  margin-top: -180px;
  position: relative;
  z-index: 1;
}
.sp-v5-property-details .sp-lg-title,
.sp-v5-property-details .text,
.sp-v5-property-details .price,
.sp-v5-property-details a {
  color: #ffffff;
}
@media (max-width: 1199.98px) {
  .sp-v5-property-details .sp-lg-title,
  .sp-v5-property-details .text,
  .sp-v5-property-details .price,
  .sp-v5-property-details a {
    color: inherit;
  }
}
.sp-v5-property-details .icon:hover {
  background-color: #5bbb7b;
  border-color: #5bbb7b;
  color: #ffffff;
}
@media (max-width: 1199.98px) {
  .sp-v5-property-details {
    margin-top: 0;
  }
}

.tab-map-sp-v5 {
  position: relative;
}
.tab-map-sp-v5:before {
  background-color: rgba(34, 34, 34, 0.3);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .tab-map-sp-v5:before {
    display: none;
  }
}

.sp-v9-forn {
  left: 20px;
  max-width: 390px;
  position: absolute;
  top: 20px;
  width: 100%;
}

.categories_list_section {
  border-bottom: 1px solid #e9e9e9;
  padding: 7px 0 3px;
}

.listings_category_nav_list_menu {
  position: relative;
}
.listings_category_nav_list_menu ul {
  width: 1265px;
}
.listings_category_nav_list_menu li {
  padding: 0 25px 0 0;
}
.listings_category_nav_list_menu li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.listings_category_nav_list_menu a {
  display: block;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  position: relative;
}
.listings_category_nav_list_menu a.active {
  font-weight: 500;
}
.listings_category_nav_list_menu a:before {
  background-color: var(--headings-color);
  bottom: -3px;
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  width: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.listings_category_nav_list_menu a.active:before,
.listings_category_nav_list_menu a:hover:before {
  left: 0;
  right: 0;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .listings_category_nav_list_menu {
    overflow-x: auto;
  }
}
.listings_category_nav_list_menu.at-home4 a:before {
  bottom: 0;
}

/* About Pages Design Content Styles */
.about-box-1 {
  position: relative;
}
.about-box-1 .list-style1 li {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}

.about-box2 {
  background-color: rgba(91, 187, 123, 0.07);
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 60px 60px 160px;
  position: relative;
}
@media (max-width: 991.98px) {
  .about-box2 {
    padding: 40px 30px;
  }
}
.about-box2 .title {
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.02em;
}
.about-box2 .img-1 {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 991.98px) {
  .about-box2 .img-1 {
    display: none;
  }
}

.freelancer-widget {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  max-width: 345px;
  padding: 40px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .freelancer-widget {
    padding: 40px 20px;
  }
}
.freelancer-widget .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}

.exclusive-agent-single {
  background-color: #ffffff;
  bottom: -90px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  max-width: 250px;
  padding: 20px;
  position: absolute;
  right: 70px;
  bottom: -60px;
  right: 30px;
}
.exclusive-agent-single .agent-img {
  border-radius: 4px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .exclusive-agent-single {
    bottom: -20px;
  }
}
@media (max-width: 767.98px) {
  .exclusive-agent-single {
    bottom: 0;
    position: relative;
    right: 0;
  }
}

.left-top-img {
  position: absolute;
  left: -60px;
  top: 0;
}
@media (max-width: 767.98px) {
  .left-top-img {
    width: 40%;
  }
}

.right-bottom-img {
  bottom: -50px;
  position: absolute;
  right: 0;
}
@media (max-width: 767.98px) {
  .right-bottom-img {
    width: 50%;
  }
}

.service-v1-vector {
  bottom: -10px;
  position: absolute;
  right: 5%;
}
.service-v1-vector.at-job {
  bottom: 0;
}

.vector-v12 {
  bottom: 0;
  position: absolute;
  right: 6.6%;
  top: 0;
}

.service-v3-vector {
  bottom: 0;
  position: absolute;
  right: 5%;
}

.imgbox-about-page .img-1 {
  bottom: 500px;
  position: absolute;
  right: -100px;
}
.imgbox-about-page .img-2 {
  left: 230px;
  opacity: 0.2;
  position: absolute;
  top: -100px;
}
.imgbox-about-page .img-3 {
  left: -100px;
  opacity: 0.2;
  position: absolute;
  top: -100px;
}
.imgbox-about-page .img-4 {
  bottom: 500px;
  left: -100px;
  position: absolute;
}
.imgbox-about-page .img-5 {
  position: absolute;
  right: 60px;
  top: -60px;
}

.imgbox-1 {
  position: relative;
}
.imgbox-1 .img-1 {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
}
@media (max-width: 400px) {
  .imgbox-1 .img-1 {
    width: 100%;
  }
}

.floating-img-1 {
  position: absolute;
  left: -150px;
  top: -150px;
}

.floating-img-2 {
  position: absolute;
  right: 0;
  top: -50px;
}

.floating-img-3 {
  position: absolute;
  right: -150px;
}

.home4-about-1 {
  position: relative;
}
.home4-about-1 .iconbox-small1 {
  top: -35px;
  top: 0;
}
.home4-about-1 .iconbox-small2 {
  top: 0;
  right: 0;
}
.home4-about-1 .img-1 {
  bottom: -30px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.home5-floating-img-1 {
  position: absolute;
  right: 4%;
}

@media (max-width: 767.98px) {
  .video-button-home8 {
    margin-bottom: -40%;
    margin-top: 40%;
  }
}

.video-button-home11 {
  background: #ffffff;
  border-radius: 50%;
  color: var(--headings-color);
  font-size: 17px;
  height: 80px;
  left: 0;
  line-height: 80px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 35%;
  text-align: center;
  width: 80px;
}

.listbox-style1 {
  max-width: 330px;
  position: relative;
  z-index: 1;
}

/*	Hero Styles All Type Of  */
/*	Home 1 Banner */
.home1-banner-content {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 35%;
  width: 80%;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .home1-banner-content {
    top: 30%;
  }
}
@media (max-width: 767.98px) {
  .home1-banner-content {
    top: 25%;
    width: 100%;
  }
}
.home1-banner-content.at-home9 {
  top: 25%;
}
@media (max-width: 767.98px) {
  .home1-banner-content.at-home9 {
    top: 8%;
  }
}

/*	Home 2 Banner  */
/*	Home 3 Banner */
.home3-hero {
  background-image: url(./images/background/home3-bg.png);
  align-items: center;
  display: flex;
  height: 670px;
}

.home3-hero-content .title {
  font-size: 45px;
  line-height: 59px;
}
@media (max-width: 767.98px) {
  .home3-hero-content .title {
    font-size: 30px;
    line-height: 45px;
  }
}

.home3-hero-img {
  bottom: -400px;
  position: absolute;
  right: -90px;
}
@media (max-width: 1600px) {
  .home3-hero-img {
    right: 0;
  }
}

.home3-hero-partner img {
  filter: contrast(0.1);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home3-hero-partner img:hover {
  filter: contrast(1);
}

/*	Home 4 Banner Content */
/*	Home 5 Slider Content */
.slider-slide-item {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 900px;
  position: relative;
}
.slider-slide-item:before {
  background-color: rgba(34, 34, 34, 0.6);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .slider-slide-item {
    height: 500px;
  }
}

.thumbimg-countnumber-carousel .slider-subtitle {
  font-family: var(--title-font-family);
  font-weight: 600;
  margin: 0 0 10px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media (max-width: 991.98px) {
  .thumbimg-countnumber-carousel .slider-subtitle {
    font-size: 26px;
  }
}
.thumbimg-countnumber-carousel .slider-title {
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 52px;
  margin: 0;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media (max-width: 991.98px) {
  .thumbimg-countnumber-carousel .slider-title {
    font-size: 36px;
  }
}
.thumbimg-countnumber-carousel .slider-text {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.thumbimg-countnumber-carousel .slider-btn {
  border-color: transparent;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.thumbimg-countnumber-carousel .active .slider-subtitle {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.thumbimg-countnumber-carousel .active .slider-title {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.thumbimg-countnumber-carousel .active .slider-text {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.thumbimg-countnumber-carousel .active .slider-btn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

/*	Home 2 Banner */
.hero-home2 {
  background-image: url(./images/background/home2-hero-bg.jpg);
  background-position: center bottom;
  background-size: cover;
  align-items: end;
  display: flex;
  height: 860px;
}
@media (max-width: 1399.98px) {
  .hero-home2 {
    align-items: center;
  }
}
@media (max-width: 1199.98px) {
  .hero-home2 {
    height: auto;
  }
}
.main-img-home2 {
  bottom: 0;
  left: -100px;
  position: absolute;
}
@media (max-width: 1400px) {
  .main-img-home2 {
    left: 0;
    width: 100%;
  }
}

.home2-hero-content .img-1 {
  left: -50px;
  position: absolute;
  top: 365px;
}
.home2-hero-content .iconbox-small1 {
  border-radius: 16px;
  padding: 20px 30px 20px 20px;
  top: 0;
}
.home2-hero-content .iconbox-small1 .icon {
  background-color: rgba(91, 187, 123, 0.15);
  color: #5bbb7b;
  left: 0;
  position: relative;
  top: 0;
}
.home2-hero-content .iconbox-small1:hover .icon {
  background-color: rgba(91, 187, 123, 0.15);
  color: #5bbb7b;
}
.home2-hero-content .iconbox-small2 {
  border-radius: 16px;
  padding: 20px 30px 20px 20px;
  right: -90px;
  top: 300px;
}
@media (max-width: 1500px) {
  .home2-hero-content .iconbox-small2 {
    right: 0;
  }
}
@media (max-width: 1400px) {
  .home2-hero-content .iconbox-small2 {
    right: -60px;
    top: 200px;
  }
}
.home2-hero-content .iconbox-small2 .icon {
  background-color: rgba(31, 75, 63, 0.1);
  color: #1f4b3f;
  left: 0;
  position: relative;
  top: 0;
}
.home2-hero-content .iconbox-small2:hover .icon {
  background-color: rgba(31, 75, 63, 0.1);
  color: #1f4b3f;
}

/*	Home 3 Banner */
/*	Home 4 Banner */
/*	Home 5 Banner */
.hero-home5 {
  align-items: center;
  background: linear-gradient(180deg, #fbf7ed 0%, rgba(251, 247, 237, 0) 100%);
  display: flex;
  height: 760px;
  margin-top: -80px;
}
@media (max-width: 991.98px) {
  .hero-home5 {
    padding-top: 120px;
  }
}

.home5-hero-content {
  position: relative;
}
.home5-hero-content .img-1 {
  bottom: 150px;
  position: absolute;
  top: auto;
  z-index: 1;
}
@media (max-width: 1199.98px) {
  .home5-hero-content .img-1 {
    bottom: 0;
  }
}
.home5-hero-content .iconbox-small1 {
  top: 200px;
}
.home5-hero-content .iconbox-small1 .icon {
  background-color: #1f4b3f;
}
.home5-hero-content .iconbox-small1:hover .icon {
  background-color: #f5cb77;
}
.home5-hero-content .iconbox-small2 {
  bottom: 200px;
  top: auto;
}
.home5-hero-content .iconbox-small2 .icon {
  background-color: #1f4b3f;
}
.home5-hero-content .iconbox-small2:hover .icon {
  background-color: #f5cb77;
}

/*	Home 6 Banner */
.hero-home6:before {
  background-color: #faf7ee;
  bottom: 0;
  content: "";
  height: 115%;
  position: absolute;
  right: 0;
  top: -80px;
  width: 29.5%;
  z-index: -1;
}

.home6-hero-content {
  position: relative;
}
.home6-hero-content .img-1 {
  position: absolute;
  top: 30%;
}
.home6-hero-content .img-2 {
  position: absolute;
  right: 0;
  top: 20px;
}
.home6-hero-content .img-3 {
  position: absolute;
  right: -10px;
  top: 50%;
}
.home6-hero-content .img-4 {
  position: absolute;
  left: 90px;
  top: 100px;
}
.home6-hero-content .img-5 {
  position: absolute;
  right: 160px;
  top: -10px;
}
.home6-hero-content .iconbox-small1 {
  bottom: 170px;
  top: auto;
}
.home6-hero-content .iconbox-small1 .icon {
  background-color: #48576e;
}
.home6-hero-content .iconbox-small1:hover .icon {
  background-color: #577799;
}
.home6-hero-content .iconbox-small2 {
  bottom: 70px;
  top: auto;
}
.home6-hero-content .iconbox-small2 .icon {
  background-color: #48576e;
}
.home6-hero-content .iconbox-small2:hover .icon {
  background-color: #577799;
}

.home6-newslatter {
  background-image: url(./images/background/home6-newslatter-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

/*	Home 7 Banner */
.hero-home7 {
  align-items: center;
  display: flex;
  height: 650px;
  position: relative;
}
@media (max-width: 1199.98px) {
  .hero-home7 {
    height: auto;
  }
}
.hero-home7:before {
  background-color: #f1fcfa;
  content: "";
  height: 513px;
  position: absolute;
  right: 0;
  top: 70px;
  width: 513px;
}
@media (max-width: 991.98px) {
  .hero-home7:before {
    display: none;
  }
}

.home7-hero-slide {
  position: relative;
}
.home7-hero-slide .details {
  position: absolute;
  left: 30px;
  top: 30px;
}

.home7-hero-slider:before {
  background-color: #ffede8;
  bottom: -50px;
  content: "";
  height: 277px;
  left: -50px;
  position: absolute;
  right: -50px;
}
@media (max-width: 1199.98px) {
  .home7-hero-slider:before {
    bottom: -30px;
    left: -30px;
    right: -30px;
  }
}

/*	Home 8 Banner */
.hero-home8 {
  background-image: url(./images/background/home8-hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  height: 800px;
  position: relative;
}

.home8-hero-img {
  position: absolute;
  right: 0;
  top: 80px;
}
/*	Home 9 Banner */
.home9-hero-content .banner-style-one .slide {
  display: flex;
  height: 780px;
  align-items: center;
}
@media (max-width: 767.98px) {
  .home9-hero-content .banner-style-one .slide {
    height: 650px;
  }
}

.home9-tags a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  display: inline-block;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  margin-right: 10px;
  padding: 5px 24px;
}
.home9-tags a:last-child {
  margin-right: 0;
}
.home9-tags.at-home12 a {
  background-color: #ffffff;
  color: #222222;
}

/*	Home 10 Banner */
.home10-hero-content .banner-style-one .slide {
  display: flex;
  height: 700px;
  align-items: center;
}
@media (max-width: 767.98px) {
  .home10-hero-content .banner-style-one .slide {
    height: 500px;
  }
}
.home10-hero-content .navi_pagi_bottom_center.owl-theme .owl-nav {
  bottom: 45px;
}
.home10-hero-content .navi_pagi_bottom_center.owl-theme .owl-dots {
  bottom: 23px;
}

/*	Home 11 Banner */
.home11-hero-content {
  position: relative;
}
.home11-hero-content .tag {
  background-color: #ffede8;
  border-radius: 12px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 13px;
  padding: 5px 17px;
}
.home11-hero-content .title {
  font-size: 55px;
  line-height: 72px;
}
@media (max-width: 767.98px) {
  .home11-hero-content .title {
    font-size: 30px;
    line-height: 45px;
  }
}

.home11-hero-img {
  position: relative;
}
.home11-hero-img .iconbox-small1 {
  border-radius: 16px;
  padding: 20px 30px 20px 20px;
  bottom: 0;
  -webkit-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -moz-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -o-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  top: auto;
}
@media (max-width: 767.98px) {
  .home11-hero-img .iconbox-small1 {
    position: absolute;
  }
}
.home11-hero-img .iconbox-small1 .icon {
  background-color: #222222;
  color: #ffffff;
  left: 0;
  position: relative;
  top: 0;
}
.home11-hero-img .iconbox-small1:hover .icon {
  background-color: #48576e;
  color: #222222;
}
.home11-hero-img .iconbox-small2 {
  border-radius: 16px;
  -webkit-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -moz-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -o-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  padding: 20px 30px 20px 20px;
  right: -90px;
  top: 300px;
}
@media (max-width: 1500px) {
  .home11-hero-img .iconbox-small2 {
    right: 0;
  }
}
@media (max-width: 1400px) {
  .home11-hero-img .iconbox-small2 {
    right: -30px;
    top: 200px;
  }
}
@media (max-width: 767.98px) {
  .home11-hero-img .iconbox-small2 {
    position: absolute;
    right: 0;
  }
}
.home11-hero-img .iconbox-small2 .icon {
  background-color: #eafac1;
  color: #222222;
  left: 0;
  position: relative;
  top: 0;
}
.home11-hero-img .iconbox-small2:hover .icon {
  background-color: #eafac1;
  color: #222222;
}

/*	Home 12 Banner */
.hero-home12 {
  background-image: url(./images/background/home12-hero-bg.png);
  display: flex;
  align-items: center;
  height: 860px;
}

.home12-hero-content {
  position: relative;
}
.home12-hero-content .tag {
  background-color: #eafac1;
  border-radius: 120px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 13px;
  padding: 5px 17px;
}

.home12-hero-img .img-0 {
  bottom: 0;
  position: absolute;
}
.home12-hero-img .img-1 {
  left: 70px;
  position: absolute;
  top: 0;
}
.home12-hero-img .img-2 {
  right: -100px;
  position: absolute;
  top: 100px;
  width: 108px;
  z-index: 1;
}
@media (max-width: 1500px) {
  .home12-hero-img .img-2 {
    right: 0;
    top: 0;
  }
}
.home12-hero-img .img-3 {
  position: absolute;
  right: -100px;
  top: 450px;
  width: 80px;
  z-index: 1;
}
@media (max-width: 1500px) {
  .home12-hero-img .img-3 {
    right: 0;
  }
}
.home12-hero-img .iconbox-small1 {
  border-radius: 16px;
  -webkit-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -moz-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -o-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  margin-left: 4%;
  padding: 20px 30px 20px 20px;
  bottom: 50px;
  top: auto;
}
@media (max-width: 767.98px) {
  .home12-hero-img .iconbox-small1 {
    position: absolute;
  }
}
.home12-hero-img .iconbox-small1 .icon {
  background-color: #cef9d6;
  color: #222222;
  left: 0;
  position: relative;
  top: 0;
}
.home12-hero-img .iconbox-small1:hover .icon {
  background-color: #cef9d6;
  color: #222222;
}
.home12-hero-img .iconbox-small2 {
  border-radius: 16px;
  -webkit-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -moz-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -o-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  bottom: 250px;
  padding: 20px 30px 20px 20px;
  right: 200px;
  top: auto;
}
@media (max-width: 1500px) {
  .home12-hero-img .iconbox-small2 {
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .home12-hero-img .iconbox-small2 {
    position: absolute;
    right: 0;
  }
}
.home12-hero-img .iconbox-small2 .icon {
  background-color: #222222;
  color: #ffffff;
  left: 0;
  position: relative;
  top: 0;
}
.home12-hero-img .iconbox-small2:hover .icon {
  background-color: #222222;
  color: #ffffff;
}

/*	Home 13 Banner */
/*	Home 14 Banner */
/*	Home 15 Banner */
/*	Home 16 Banner */
/*	Home 17 Banner */
/*	Home 18 Banner */
/*	Home 19 Banner */
/*	Home 20 Banner */
/*	ALl Blogs Styles Here	*/
.blog-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-style1 .blog-img {
  overflow: hidden;
}
.blog-style1 .blog-img img {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-style1 .blog-content {
  padding: 30px;
  position: relative;
}
.blog-style1 .date {
  color: var(--body-text-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
}
.blog-style1 .title {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-style1:hover .blog-img img {
  transform: scale(1.1) rotate(-1deg);
}
.blog-style1:hover .title,
.blog-style1:hover .title a {
  color: #5bbb7b;
  text-decoration: underline;
}
.blog-style1.large-size {
  border-radius: 4px;
  box-shadow: none;
  overflow: hidden;
}
.blog-style1.large-size .blog-img {
  border-radius: 0;
  overflow: hidden;
}
.blog-style1.large-size .title {
  font-size: 28px;
}
.blog-style1.list-style {
  border-radius: 4px;
  box-shadow: none;
  overflow: hidden;
}
.blog-style1.list-style .blog-img {
  border-radius: 4px;
  position: relative;
}
.blog-style1.list-style .title {
  font-size: 28px;
}
.blog-style1.at-home5 {
  border-radius: 8px;
  box-shadow: none;
}
.blog-style1.at-home5.active,
.blog-style1.at-home5:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.blog-style1.at-home6 {
  box-shadow: none;
}
.blog-style1.at-home7 {
  box-shadow: none;
}
.blog-style1.at-home7:hover {
  border: 1px solid #222222;
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}
.blog-style1.at-home8 {
  box-shadow: none;
}
.blog-style1.at-home8 .blog-content {
  padding: 20px 0;
}

.custom_bsp_grid,
.list-style-type-bullet {
  position: relative;
}
.custom_bsp_grid li,
.list-style-type-bullet li {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 46px;
  list-style-type: disc;
}
.custom_bsp_grid li::marker,
.list-style-type-bullet li::marker {
  color: #1f4b3f;
}
.custom_bsp_grid.dashboard-style li,
.list-style-type-bullet.dashboard-style li {
  line-height: 28px;
}

.blog_post_share span {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
}
.blog_post_share a {
  border-radius: 50%;
  color: var(--headings-color);
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  width: 40px;
}
.blog_post_share a:hover {
  background-color: rgba(34, 34, 34, 0.05);
}

.bsp_tags a {
  background-color: #ffede8;
  border-radius: 60px;
  color: var(--headings-color);
  display: block;
  font-family: var(--body-font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
  padding: 6px 17px;
}

.blog-single-review {
  position: absolute;
  right: 0;
  top: 5px;
}
@media (max-width: 767.98px) {
  .blog-single-review {
    position: relative;
  }
}

.review_cansel_btns a {
  color: #697488;
  font-family: var(--title-font-family);
  line-height: 26px;
  letter-spacing: 0em;
  margin-right: 30px;
}
.review_cansel_btns a:last-child {
  margin-right: 0;
}
.review_cansel_btns a:hover {
  color: var(--headings-color);
}
.review_cansel_btns i {
  padding-right: 10px;
}

.total-review-box {
  background-color: #ffede8;
  border-radius: 4px;
  height: 250px;
  position: relative;
  width: 300px;
}
.total-review-box .t-review {
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  color: #e1c03f;
}

.review-list .list-number {
  white-space: nowrap;
  font-weight: 500;
  color: var(--headings-color);
  padding-right: 10px;
  min-width: 55px;
}
.review-list .progress {
  flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  height: 5px;
  width: 300px;
}
@media (max-width: 575.98px) {
  .review-list .progress {
    width: 200px;
  }
}
.review-list .progress-bar {
  background-color: #e1c03f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  width: 67%;
}
.review-list .value {
  display: inline-block;
  font-size: 14px;
  width: 40px;
  padding-left: 10px;
}

/*	Brands  */
/*  Commons Styles of Template  */
section {
  padding: 120px 0;
  position: relative;
}
@media (max-width: 991.98px) {
  section {
    padding: 60px 0;
  }
}

.main-title,
.main-title2 {
  position: relative;
  margin-bottom: 60px;
}
@media (max-width: 991.98px) {
  .main-title,
  .main-title2 {
    margin-bottom: 30px;
  }
}
.main-title .title,
.main-title2 .title {
  font-style: normal;
}
.main-title .paragraph,
.main-title2 .paragraph {
  color: var(--headings-color);
  font-family: var(--title-font-family);
}

@media (max-width: 991.98px) {
  .main-title2 {
    margin-bottom: 30px;
  }
}

.ui-content {
  position: relative;
  margin-bottom: 30px;
}
.ui-content .title {
  margin-bottom: 30px;
}

.mouse_scroll {
  bottom: 0;
  position: absolute;
  right: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .mouse_scroll {
    right: 15px;
  }
}
@media (max-width: 575.98px) {
  .mouse_scroll {
    display: none;
  }
}
.mouse_scroll.at-home8 {
  bottom: 110px;
  right: 0;
  left: 0;
}

.list-style1 {
  position: relative;
}
.list-style1 li {
  align-items: center;
  display: flex;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  list-style-type: none;
  margin-bottom: 20px;
}
.list-style1 i {
  border-radius: 50%;
  font-size: 8px;
  height: 18px;
  left: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  width: 18px;
}

.list-style2 {
  position: relative;
}
.list-style2 li {
  align-items: center;
  display: flex;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  list-style-type: none;
  margin-bottom: 20px;
}
.list-style2 i {
  border-radius: 50%;
  font-size: 16px;
  height: 18px;
  left: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  width: 18px;
}
.list-style2.light-style li,
.list-style2.light-style i {
  color: #ffffff;
}

.list-style3 {
  position: relative;
}
.list-style3 li {
  align-items: center;
  display: flex;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  list-style-type: none;
  margin-bottom: 20px;
  padding-left: 5px;
}
.list-style3 i {
  border-radius: 50%;
  font-size: 10px;
  height: 25px;
  left: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  width: 25px;
}

.skill-list-style1 a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.skill-list-style1 a:hover {
  color: #5bbb7b;
  text-decoration: underline;
}

/* Custom Search Sugguestions Code Start */
.advance-search-tab.at-home3 {
  border: 1px solid #397e62;
}

.advance-search-tab.at-home6 {
  border: 1px solid #1f4b3f;
}
.advance-search-tab.at-home7 {
  border: 1px solid #222222;
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}

.advance-search-field .box-search .icon {
  bottom: 13px;
  color: var(--headings-color);
  font-size: 16px;
  left: 20px;
  position: absolute;
}
.advance-search-field .box-search input {
  border: none;
  font-size: 15px;
  font-family: var(--title-font-family);
  outline: none;
  padding-left: 50px;
}
.advance-search-field .box-search input:focus {
  border: none;
  outline: none;
}
.advance-search-field .box-search input::placeholder {
  color: var(--headings-color);
}
.advance-search-field .search-suggestions {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  left: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 70px;
  visibility: hidden;
  width: 100%;
  z-index: 99;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.advance-search-field .search-suggestions.show {
  opacity: 1;
  visibility: visible;
}
.advance-search-field .box-suggestions {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}
.advance-search-field .box-suggestions li {
  overflow: hidden;
  padding: 8px 30px;
  font-family: var(--title-font-family);
  font-weight: 400;
}
.advance-search-field .box-suggestions li:hover {
  background-color: #f0efec;
}
.advance-search-field .box-suggestions li:last-child {
  border-bottom: none;
}
.advance-search-field .item_title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

/* Custom Search Sugguestions Code End */
.preloader {
  background-color: #eaeaea;
  background-image: url("./images/preloader.gif");
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999;
}

.scrollToHome {
  background-color: rgba(34, 34, 34, 0.05);
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-radius: 50%;
  bottom: -45px;
  color: #222222;
  display: block;
  height: 50px;
  line-height: 50px;
  opacity: 0;
  position: fixed;
  right: 45px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  z-index: 9;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.scrollToHome:hover {
  color: #ffffff;
}
.scrollToHome.at-home2 {
  background-color: rgba(255, 255, 255, 0.05);
  color: #ffffff;
}

.scrollToHome:hover {
  background-color: #1f4b3f;
  color: #ffffff;
}

.scrollToHome.show {
  bottom: 45px;
  right: 45px;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.animate-up-1,
.animate-up-2,
.animate-up-3,
.animate-up-4,
.animate-up-5 {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animate-up-1 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animate-up-2 {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animate-up-3 {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.animate-up-4 {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.animate-up-5 {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.line-clamp1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/*	Contact Styles  */
.home8-map {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.home8-map.contact-page {
  height: 550px;
}
@media (max-width: 991.98px) {
  .home8-map.contact-page {
    height: 350px;
    position: relative;
  }
}

.contact-page-map {
  border-radius: 16px;
  height: 510px;
}

.contact-page-form {
  margin-top: -200px;
}
@media (max-width: 991.98px) {
  .contact-page-form {
    margin-top: 0;
  }
}

/*	All CTA Styles Here  */
.cta-service-v1 {
  background-image: url(./images/background/cta-service-v1-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 767.98px) {
  .cta-service-v1.freelancer-single-style {
    height: auto;
  }
}

.cta-service-v2 {
  background-image: url(./images/background/cta-service-v2-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.cta-service-v3 {
  background-image: url(./images/background/cta-service-v3-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.cta-service-v4 {
  background-image: url(./images/background/cta-service-v4-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.cta-service-v6 {
  background-image: url(./images/background/cta-service-v6-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 767.98px) {
  .cta-service-v6 {
    height: auto;
  }
}

.cta-service-single {
  background-image: url(./images/background/cta-service-signle-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 767.98px) {
  .cta-service-single {
    height: auto;
  }
}

.cta-employee-single {
  background-image: url(./images/background/cta-employee-list-v1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 767.98px) {
  .cta-employee-single {
    height: auto;
  }
}

.cta-job-v1 {
  background-image: url(./images/background/cta-job-list-v1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 767.98px) {
  .cta-job-v1 {
    height: auto;
  }
}

.cta-commmon-v1 {
  height: 300px;
}
@media (max-width: 991.98px) {
  .cta-commmon-v1 {
    height: 200px;
  }
}

.cta-about-v1 {
  background-image: url(./images/background/cta-about-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.cta-home3-last {
  background-image: url(./images/background/home3-cta-last.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.cta-style1 {
  position: relative;
}
.cta-style1 .cta-title {
  letter-spacing: 0.02em;
}
.cta-style1 .cta-text {
  font-size: 15px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
@media (max-width: 991.98px) {
  .cta-style1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .cta-btns-style1 .ud-btn {
    width: 100%;
  }
  .cta-btns-style1 .ud-btn:first-child {
    margin-bottom: 12px;
  }
}

.our-cta2 {
  margin-bottom: -150px;
  z-index: 1;
}

.cta-banner2 {
  position: relative;
}

.cta-banner3 .cta-banner3-img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}
@media (max-width: 1199.98px) {
  .cta-banner3 .cta-banner3-img {
    height: auto;
    left: 15px;
    position: relative;
    right: 15px;
    width: 96%;
  }
}
.cta-banner3.at-home8:before {
  left: 0;
  right: auto;
}
.cta-banner3.at-home8:before {
  left: 0;
  right: auto;
  height: 93%;
}

.cta-style2 {
  position: relative;
}
.cta-style2 .cta-title {
  letter-spacing: 0.02em;
}
.cta-style2 .cta-title,
.cta-style2 .cta-text {
  color: #ffffff;
}
.cta-style2 .cta-text {
  font-size: 15px;
  font-family: var(--title-font-family);
  font-weight: 400;
}
.cta-style2 img {
  left: auto;
  right: 0;
  position: absolute;
  top: -65px;
}

.cta-style3 .cta-title {
  font-family: var(--body-font-family);
}
.cta-style3 .cta-text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}

.cta-banner4 {
  background-attachment: fixed;
  /* background-image: url(./images/background/cta-bg-1.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
}

@media (max-width: 575.98px) {
  .cta-style4 .cta-title {
    font-size: 30px;
  }
}

.cta-img {
  bottom: -90px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .cta-img {
    bottom: -60px;
  }
}
@media (max-width: 767.98px) {
  .cta-img {
    position: relative;
  }
}

.our-cta3 {
  background-color: #f8f8ff;
}

.cta-banner5:before {
  background-image: url(./images/about/about-7.jpg);
  background-size: cover;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 51%;
}
@media (max-width: 767.98px) {
  .cta-banner5:before {
    display: none;
  }
}

.cta-style5 .app-tag {
  background-color: #fffbf2;
  border-radius: 60px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  display: block;
  height: 34px;
  text-align: center;
  line-height: 34px;
  width: 128px;
}
.cta-style5 .cta-text {
  color: #140342;
  font-family: var(--title-font-family);
  font-weight: 400;
}

.cta-banner6:before {
  /* background-image: url(./images/about/cta-side-bg-3.jpg); */
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 51%;
}
@media (max-width: 1199.98px) {
  .cta-banner6:before {
    display: none;
  }
}

.cta-style6 .cta-title {
  color: var(--headings-color);
  font-family: var(--body-font-family);
  font-size: 32px;
}
.cta-style6 .cta-text {
  color: #1f4b3f;
}

.cta-about2-img {
  position: absolute;
  right: 0;
}
.cta-about2-img.at-home8-2 {
  top: 0;
}
.cta-about2-img.at-home10 {
  left: 0;
  right: auto;
}

.home2-cta-img,
.home6-cta-img,
.home10-cta-img {
  border-radius: 0 0 0 90px;
  bottom: 0;
  position: absolute;
  right: 13%;
  z-index: -1;
}
@media (max-width: 1199.98px) {
  .home2-cta-img,
  .home6-cta-img,
  .home10-cta-img {
    right: 0;
    top: 10%;
    width: 45%;
  }
}
@media (max-width: 991.98px) {
  .home2-cta-img,
  .home6-cta-img,
  .home10-cta-img {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    right: 0;
    max-width: 350px;
    top: auto;
    width: 100%;
  }
}

.home6-cta-img {
  border-radius: 0;
  z-index: 0;
}

.cta-banner-about2 {
  align-items: center;
  display: flex;
  height: 900px;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2 {
    height: auto;
  }
}
.cta-banner-about2:before {
  background-color: #f1fcfa;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 71%;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2:before {
    width: 100%;
  }
}
.cta-banner-about2.at-home2 {
  height: 720px;
}
.cta-banner-about2.at-home2:before {
  display: none;
}
@media (max-width: 991.98px) {
  .cta-banner-about2.at-home2 {
    display: block;
    height: auto;
  }
}
.cta-banner-about2.at-home8 {
  height: 750px;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2.at-home8 {
    height: 600px;
  }
}
@media (max-width: 991.98px) {
  .cta-banner-about2.at-home8 {
    display: block;
    height: auto;
  }
}
.cta-banner-about2.at-home8:before {
  background-color: #fbf7ed;
  left: auto;
  right: 0;
  width: 91%;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2.at-home8:before {
    right: auto;
    left: 0;
    width: 100%;
  }
}
.cta-banner-about2.at-home8-2 {
  height: 790px;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2.at-home8-2 {
    height: 700px;
  }
}
@media (max-width: 991.98px) {
  .cta-banner-about2.at-home8-2 {
    display: block;
    height: auto;
  }
}
.cta-banner-about2.at-home8-2:before {
  background-color: #102c2d;
  left: 0;
  right: auto;
  width: 91%;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2.at-home8-2:before {
    right: auto;
    left: 0;
    width: 100%;
  }
}
.cta-banner-about2.at-home10:before {
  left: auto;
  right: 0;
}
.cta-banner-about2.at-home10-2 {
  height: 800px;
}
@media (max-width: 1199.98px) {
  .cta-banner-about2.at-home10-2 {
    height: auto;
  }
}
.cta-banner-about2.at-home10-2:before {
  background-color: #ffede8;
  border-radius: 24px;
}
.cta-banner-about2.at-home10-2.at-home6 {
  border-radius: 0;
}

.cta-home4 {
  height: 500px;
}
@media (max-width: 991.98px) {
  .cta-home4 {
    height: auto;
  }
}
.cta-home4.at-home12 {
  background-image: url(./images/background/home12-cta-1.png);
}

.cta-home5-style {
  border-radius: 12px 0 0 0;
}

.cta-banner-home7 {
  position: relative;
}
.cta-banner-home7:before {
  background-color: #f1fcfa;
  border-radius: 4px;
  content: "";
  height: 321px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 45%;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .cta-banner-home7:before {
    width: 95%;
  }
}

.cta-home8 {
  background: linear-gradient(
    90deg,
    #f0efec -0.44%,
    rgba(251, 247, 237, 0.7) 101.75%
  );
  height: 500px;
}
@media (max-width: 991.98px) {
  .cta-home8 {
    height: auto;
  }
}

.cta-img-home8 {
  position: absolute;
  bottom: -115px;
}
.cta-img-home8.at-home7 {
  bottom: -90px;
}
.cta-img-home8.at-home12 {
  right: 0px;
}

.home8-cta-img {
  left: 0;
  position: absolute;
  width: 45%;
  top: 0;
}
@media (max-width: 991.98px) {
  .home8-cta-img {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
  }
}

.home8-cta-img2 {
  right: 0;
  position: absolute;
  width: 45%;
  top: 0;
}
@media (max-width: 1199.98px) {
  .home8-cta-img2 {
    top: 22%;
  }
}
@media (max-width: 767.98px) {
  .home8-cta-img2 {
    left: 15%;
    position: relative;
    width: 60%;
    margin-bottom: 30px;
  }
}
@media (max-width: 575.98px) {
  .home8-cta-img2 {
    left: 5px;
    width: 96%;
  }
}

.home10-cta-img {
  right: 8%;
  top: 5%;
  z-index: 0;
}
@media (max-width: 1199.98px) {
  .home10-cta-img {
    right: 0;
    top: 15%;
  }
}
@media (max-width: 991.98px) {
  .home10-cta-img {
    bottom: 0;
    top: auto;
  }
}

.home11-cta-2 {
  background-image: url(./images/background/home11-cta-2.png);
}

.home11-cta-3 {
  background-image: url(./images/background/home11-cta-3.png);
  display: flex;
  align-items: center;
  height: 500px;
}
@media (max-width: 767.98px) {
  .home11-cta-3 {
    height: auto;
  }
}

.home11-ctaimg-v3 {
  bottom: 0;
  position: absolute;
}

/*	Error Pages Styles  */
.error_page_content .erro_code {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 700;
  line-height: 289px;
  font-size: 200px;
}
@media (max-width: 575.98px) {
  .error_page_content .erro_code {
    font-size: 150px;
  }
}

/*	Iconbox Styles  */
.iconbox-style1 {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 40px 30px 30px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.iconbox-style1 .icon {
  color: #1f4b3f;
  display: inline-block;
  font-size: 40px;
  position: relative;
  z-index: 1;
}
.iconbox-style1 .icon:before {
  background-color: #fbf7ed;
  border-radius: 50%;
  bottom: -10px;
  content: "";
  height: 40px;
  position: absolute;
  right: -20px;
  width: 40px;
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.iconbox-style1 .title {
  margin-bottom: 5px;
}
.iconbox-style1:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.iconbox-style1.contact-style {
  border: none;
  padding: 0;
}
.iconbox-style1.contact-style .details {
  padding-left: 35px;
}
.iconbox-style1.contact-style:hover {
  box-shadow: none;
}
.iconbox-style1.border-less {
  border: none;
}
.iconbox-style1.border-less:hover {
  box-shadow: none;
}
.iconbox-style1.at-home4 .icon {
  border-radius: 50%;
  -webkit-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -moz-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -o-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  display: inline-block;
  height: 110px;
  line-height: 120px;
  margin-bottom: 20px;
  width: 110px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.iconbox-style1.at-home4:hover {
  box-shadow: none;
}
.iconbox-style1.at-home4:hover .icon {
  background-color: #5bbb7b;
  box-shadow: none;
  color: #ffffff;
}
.iconbox-style1.at-home5:hover {
  box-shadow: none;
}
.iconbox-style1.at-home6 .icon {
  color: #cbefd2;
}
.iconbox-style1.at-home7 {
  border-radius: 0;
}
.iconbox-style1.at-home7:hover {
  border: 1px solid #222222;
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}
.iconbox-style1.at-home11 .icon {
  background-color: #e5f0e3;
  border-radius: 50%;
  color: #325854;
  display: inline-block;
  font-size: 30px;
  height: 70px;
  line-height: 80px;
  margin-bottom: 20px;
  width: 70px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.iconbox-style1.at-home11:hover {
  box-shadow: none;
}
.iconbox-style1.at-home11:hover .icon {
  background-color: #325854;
  box-shadow: none;
  color: #e5f0e3;
}
.iconbox-style1.at-home11-v2 .icon:before {
  background-color: #f9fbfa;
}
.iconbox-style1.at-home11-v2:hover {
  background-color: #266464;
}
.iconbox-style1.at-home11-v2:hover .icon {
  color: #ffffff;
}
.iconbox-style1.at-home11-v2:hover .icon:before {
  background-color: rgba(255, 255, 255, 0.07);
}
.iconbox-style1.at-home11-v2:hover .text,
.iconbox-style1.at-home11-v2:hover .title {
  color: #ffffff;
}
.iconbox-style1.at-home12 .icon {
  background-color: transparent;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  display: inline-block;
  height: 110px;
  line-height: 120px;
  margin-bottom: 20px;
  width: 110px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.iconbox-style1.at-home12:hover {
  box-shadow: none;
}
.iconbox-style1.at-home12:hover .icon {
  background-color: #222222;
  box-shadow: none;
  color: #ffffff;
}
.iconbox-style1.at-home12-v2 {
  box-shadow: none;
}
.iconbox-style1.at-home12-v2:hover .icon:before {
  background-color: #eafac1;
}

.iconbox-small1 {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 25px 55px 20px 35px;
  position: absolute;
  top: 130px;
  z-index: 1;
}
@media (max-width: 575.98px) {
  .iconbox-small1 {
    position: relative;
    top: 0;
  }
}
.iconbox-small1 .icon {
  background-color: #5bbb7b;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  left: -25px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: -25px;
  width: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-small1:hover .icon {
  background-color: #1f4b3f;
}
.iconbox-small1.at-home1 {
  left: -45px;
}
@media (max-width: 1550px) {
  .iconbox-small1.at-home1 {
    left: -10px;
  }
}
@media (max-width: 1440px) {
  .iconbox-small1.at-home1 {
    left: 0;
  }
}

.iconbox-small2 {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 25px 55px 20px 35px;
  position: absolute;
  right: -30px;
  top: 200px;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .iconbox-small2 {
    right: 0;
  }
}
@media (max-width: 575.98px) {
  .iconbox-small2 {
    position: relative;
    top: 30px;
  }
}
.iconbox-small2 .icon {
  background-color: #5bbb7b;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  left: -25px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: -25px;
  width: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-small2:hover .icon {
  background-color: #1f4b3f;
}

.iconbox-style2 {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 60px 35px 20px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .iconbox-style2 {
    padding: 60px 25px 20px;
  }
}
.iconbox-style2.active,
.iconbox-style2:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.iconbox-style2 .icon {
  margin-bottom: 25px;
}
.iconbox-style2 .title {
  margin-bottom: 12px;
}
.iconbox-style2 .text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  margin-bottom: 25px;
}

.iconbox-style3 {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 60px 35px 20px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .iconbox-style3 {
    padding: 60px 25px 20px;
  }
}
.iconbox-style3.active,
.iconbox-style3:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.iconbox-style3 img {
  max-height: 150px;
}
.iconbox-style3 .icon {
  margin-bottom: 25px;
}
.iconbox-style3 .title {
  margin-bottom: 12px;
}
.iconbox-style3 .text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  margin-bottom: 25px;
}

.iconbox-style4 {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style4 .icon {
  background-color: rgba(91, 187, 123, 0.07);
  border-radius: 50%;
  color: var(--headings-color);
  display: block;
  font-size: 30px;
  height: 70px;
  line-height: 75px;
  margin-bottom: 15px;
  text-align: center;
  width: 70px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style4 .title {
  margin-bottom: 5px;
}
.iconbox-style4:hover {
  background-color: #5bbb7b;
}
.iconbox-style4:hover .title,
.iconbox-style4:hover .text,
.iconbox-style4:hover .icon {
  color: #ffffff;
}
.iconbox-style4:hover .icon {
  background-color: rgba(255, 255, 255, 0.1);
}

.iconbox-style5 {
  background-color: #ffffff;
  bottom: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  max-width: 210px;
  padding: 20px;
  position: absolute;
  right: -50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .iconbox-style5 {
    right: -10px;
  }
}
.iconbox-style5 .icon {
  background-color: #5bbb7b;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font-size: 24px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  width: 60px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style5 .title {
  margin-bottom: 5px;
}
.iconbox-style5:hover {
  background-color: #5bbb7b;
}
.iconbox-style5:hover .title,
.iconbox-style5:hover .text,
.iconbox-style5:hover .icon {
  color: #ffffff;
}
.iconbox-style5:hover .icon {
  background-color: rgba(255, 255, 255, 0.1);
}

.iconbox-style6 {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 45px 30px 20px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style6 .title {
  color: #5bbb7b;
}
.iconbox-style6 .icon {
  color: #5bbb7b;
  font-size: 36px;
  position: absolute;
  right: 30px;
  top: 17px;
}
.iconbox-style6 .iconbox-text {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 13px;
}
.iconbox-style6:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.iconbox-style7 {
  background-color: #ffffff;
  bottom: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  max-width: 210px;
  padding: 20px;
  position: absolute;
  right: -50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .iconbox-style7 {
    right: -10px;
  }
}
.iconbox-style7 .icon {
  background-color: #222222;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font-size: 24px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  width: 60px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style7 .title {
  margin-bottom: 5px;
}
.iconbox-style7:hover {
  background-color: #5bbb7b;
}
.iconbox-style7:hover .title,
.iconbox-style7:hover .text,
.iconbox-style7:hover .icon {
  color: #ffffff;
}
.iconbox-style7:hover .icon {
  background-color: rgba(255, 255, 255, 0.1);
}

.iconbox-style9 {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.iconbox-style9 .icon {
  color: #1f4b3f;
  display: block;
  font-size: 40px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.apartment-category {
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 70px;
  margin-bottom: 30px;
  padding: 10px 30px 10px 10px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.apartment-category .icon {
  background-color: #ffffff;
  border-radius: 50%;
  color: var(--headings-color);
  font-size: 20px;
  height: 50px;
  line-height: 55px;
  text-align: center;
  width: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.apartment-category .title {
  margin-left: 12px;
}
.apartment-category:hover {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.apartment-category:hover .icon {
  background-color: #5bbb7b;
  color: #ffffff;
}

.feature-style1 {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.feature-style1 .feature-img {
  position: relative;
}
.feature-style1 img {
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-style1 .feature-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-style1 .title,
.feature-style1 .text,
.feature-style1 .ud-btn2 {
  color: #ffffff;
}
.feature-style1 .ud-btn2 {
  font-size: 14px;
  font-weight: 400;
}
.feature-style1 .top-area {
  padding-left: 30px;
  position: absolute;
  top: 35px;
}
.feature-style1:hover .feature-content {
  background-color: rgba(34, 34, 34, 0.6);
}
.feature-style1:hover img {
  transform: scale(1.1) rotate(-1deg);
}

.feature-style2 {
  position: relative;
}
.feature-style2 .feature-img {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.feature-style2 img {
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-style2 .title,
.feature-style2 .text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
}
.feature-style2:hover img {
  transform: scale(1.1) rotate(-1deg);
}

.feature-style3 {
  position: relative;
}
.feature-style3 .feature-img {
  overflow: hidden;
  position: relative;
}
.feature-style3 img {
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-style3 .feature-content {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.feature-style3:hover img {
  transform: scale(1.1) rotate(-1deg);
}

.why-chose-list {
  position: relative;
}
.why-chose-list .list-one {
  position: relative;
}
.why-chose-list .list-one .list-icon {
  color: #1f4b3f;
  display: block;
  font-size: 30px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.why-chose-list.style2 .list-one:hover .list-icon {
  background-color: rgba(91, 187, 123, 0.6);
  color: #ffffff;
}
.why-chose-list.style2 .list-icon {
  color: var(--headings-color);
}
.why-chose-list.style3 .list-icon {
  background-color: #f7f7f7;
}
.why-chose-list.style3 .list-icon {
  color: var(--headings-color);
}
.why-chose-list.at-home5 .list-icon:after {
  background-color: #fbf7ed;
  border-radius: 50%;
  content: "";
  height: 30px;
  position: absolute;
  width: 30px;
  left: 10px;
  z-index: -1;
  top: 10px;
}

.home9-city-style {
  position: relative;
  padding: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.home9-city-style:hover {
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

/*	All Type Of Forms Styles  */
.form-style1 {
  position: relative;
}
.form-style1 .form-control {
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  font-size: 15px;
  height: 55px;
  outline: none;
  padding-left: 15px;
}
.form-style1 .form-control::placeholder {
  color: #697488;
  font-family: var(--title-font-family);
}
.form-style1 .form-control.active,
.form-style1 .form-control:focus {
  border: 2px solid #1f4b3f;
  color: var(--headings-color);
}
.form-style1 .form-label {
  font-family: var(--title-font-family);
}
.form-style1 .custom_checkbox {
  line-height: 26px;
}
.form-style1 .custom_checkbox .checkmark {
  top: 7px;
}

.form-style2 {
  position: relative;
}
.form-style2 .form-control {
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  box-shadow: none;
  height: 50px;
  outline: none;
  padding-left: 15px;
}
.form-style2 .form-control:placeholder {
  color: #697488;
  font-family: var(--title-font-family);
}
.form-style2 .form-control.active,
.form-style2 .form-control:focus {
  border: 2px solid var(--headings-color);
}
.form-style2 .form-label {
  font-family: var(--title-font-family);
}

textarea {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  height: auto;
  width: 100%;
  padding: 25px 20px;
}

.form-control {
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: none;
  height: 55px;
  outline: 1px solid #e9e9e9;
  padding-left: 20px;
}
.form-control:placeholder {
  color: #697488;
  font-family: var(--title-font-family);
}
.form-control:focus {
  border: 2px solid var(--headings-color);
  box-shadow: none;
}

.bootselect-multiselect {
  position: relative;
}
.bootselect-multiselect
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}
.bootselect-multiselect .btn,
.bootselect-multiselect .btn-light {
  border-radius: 4px;
  height: 55px;
  line-height: 40px;
  overflow: hidden;
  padding-left: 15px;
  width: 100%;
}
.bootselect-multiselect .btn:focus,
.bootselect-multiselect .btn-light:focus {
  border: 2px solid var(--headings-color);
  outline: none;
  outline-offset: initial;
}
.bootselect-multiselect .btn.dropdown-toggle,
.bootselect-multiselect .btn-light.dropdown-toggle {
  width: 100%;
}
.bootselect-multiselect .btn.dropdown-toggle:focus,
.bootselect-multiselect .btn-light.dropdown-toggle:focus {
  box-shadow: none;
  outline: none !important;
}
.bootselect-multiselect .bootstrap-select.dropdown-toggle.bs-placeholder {
  color: var(--headings-color);
}
.bootselect-multiselect .dropdown-menu {
  padding: 10px 15px;
}
.bootselect-multiselect .dropdown-menu .inner {
  padding: 10px;
}
.bootselect-multiselect .dropdown-menu .dropdown-item {
  padding: 10px;
}
.bootselect-multiselect .dropdown-menu .dropdown-item.active,
.bootselect-multiselect .dropdown-menu .dropdown-item:hover,
.bootselect-multiselect .dropdown-menu .dropdown-item:focus {
  background-color: rgba(91, 187, 123, 0.07);
  color: var(--headings-color);
  outline: none;
}
.bootselect-multiselect .btn-light {
  background-color: #ffffff;
}
.bootselect-multiselect .btn-light:hover,
.bootselect-multiselect .btn-light:focus {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  outline: none;
}
.bootselect-multiselect .btn-light::placeholder {
  color: var(--headings-color);
}

.inquiry-form .form-control {
  font-size: 14px;
  font-family: var(--title-font-family);
  font-weight: 400;
}
.inquiry-form .dropdown-menu {
  padding: 0;
}

.agent-single-form {
  margin-top: -150px;
}
@media (max-width: 991.98px) {
  .agent-single-form {
    margin-top: 30px;
  }
}

.popup-search-field {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  display: flex;
  height: 100px;
}
.popup-search-field .form-control {
  border: none;
}
.popup-search-field .form-control:focus {
  border: none;
  outline: none;
}
.popup-search-field input::placeholder {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.popup-search-field.search_area .form-control {
  padding-left: 57px;
  outline: none;
}
.popup-search-field.search_area label {
  color: var(--headings-color);
  font-size: 16px;
  left: 30px;
  right: auto;
  top: initial;
}
.popup-search-field button {
  position: absolute;
  right: 20px;
}

.search_widgets {
  position: relative;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.search_widgets input::placeholder {
  color: var(--headings-color);
  font-size: 15px;
  font-weight: 400;
}
.search_widgets .search-btn {
  color: var(--headings-color);
  position: absolute;
  right: 10px;
  top: 8px;
}

/*	Gallery Styles  */
.gallery-item {
  align-items: center;
  display: flex;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.gallery-item .del-edit {
  left: 0;
  margin: 0 auto;
  right: 0;
  transform: scale(0);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: 0;
}
.gallery-item .icon {
  background-color: #ffffff;
  color: #1f4b3f;
}
.gallery-item:hover .del-edit {
  opacity: 1;
  transform: scale(1);
}

/*  Packages And Pricing Tables  */
.pricing_table_switch_slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(91, 187, 123, 0.1);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.pricing_table_switch_slide:before {
  bottom: 5px;
  content: "";
  height: 20px;
  left: 5px;
  position: absolute;
  width: 20px;
  background-color: white;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.pricing_table_switch_slide.round {
  border-radius: 30px;
}
.pricing_table_switch_slide.round:before {
  border-radius: 50%;
}

.pricing_packages_top .switch input:checked + .pricing_table_switch_slide {
  background-color: #5bbb7b;
}

.pricing_packages_top
  .toggle-btn
  .switch
  input:focus
  + .pricing_table_switch_slide {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.pricing_packages_top
  .switch
  input:checked
  + .pricing_table_switch_slide:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.pricing_packages_top .toggle-btn input,
.pricing_packages_top .toggle-btn label {
  display: inline-block;
  vertical-align: middle;
}
.pricing_packages_top .toggle-btn .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}
.pricing_packages_top .toggle-btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.pricing_packages_top .pricing_save1,
.pricing_packages_top .pricing_save2 {
  margin: 12px;
}
.pricing_packages_top .pricing_save3 {
  background-color: #ffede8;
  border-radius: 60px;
  color: #5bbb7b;
  font-family: var(--body-font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  padding: 8px 17px;
}

.pricing_packages {
  border: 1px solid transparent;
  margin-bottom: 30px;
  padding: 50px 30px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .pricing_packages {
    padding: 40px 30px;
  }
}
.pricing_packages.active,
.pricing_packages .heading {
  position: relative;
}
.pricing_packages.active .btn-dark-border {
  background-color: #222222;
  border: 2px solid #222222;
  color: #ffffff;
}
.pricing_packages.active .btn-dark-border:before {
  background-color: #222222;
}
.pricing_packages.active .btn-thm-border {
  background-color: #5bbb7b;
  border: 2px solid #5bbb7b;
  color: #ffffff;
}
.pricing_packages.active .btn-thm-border:hover {
  background-color: #5bbb7b;
  border: 2px solid #5bbb7b;
}
.pricing_packages.active.at-home2 .ud-btn {
  border-radius: 90px;
  background-color: #5bbb7b;
  color: #ffffff;
}
.pricing_packages.active.at-home2 .ud-btn:hover {
  background-color: #5bbb7b;
  border: 2px solid #5bbb7b;
}
.pricing_packages .price-icon {
  position: absolute;
  right: 0;
  top: -10px;
}
.pricing_packages .text1,
.pricing_packages .text2 {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 0;
}
.pricing_packages .text1 small,
.pricing_packages .text2 small {
  color: var(--body-text-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
}
.pricing_packages .pricing-list li {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
}
.pricing_packages.active,
.pricing_packages:hover {
  border: 1px solid #e9e9e9;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.pricing_packages.at-home2 .ud-btn {
  border-radius: 90px;
}

/*	Testimonials Styles  */
.testimonial-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 45px 40px 30px;
}
.testimonial-style1 .testimonial-content {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 30px;
}
.testimonial-style1 .icon {
  color: #f1fcfa;
  font-size: 36px;
  position: absolute;
  right: 60px;
  top: 30px;
}
.testimonial-style1 .title {
  margin-bottom: 25px;
}
.testimonial-style1 .t_content {
  line-height: 35px;
}

.testimonial-style2 {
  position: relative;
}
.testimonial-style2 .tab-content {
  height: auto;
}
.testimonial-style2 .testi-text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 28px;
  line-height: 45px;
  margin-bottom: 40px;
}
.testimonial-style2 .icon {
  color: #5bbb7b;
  font-size: 60px;
  margin-bottom: 45px;
}
.testimonial-style2 .design {
  font-family: var(--title-font-family);
  font-size: 13px;
  margin-bottom: 30px;
}
@media (max-width: 575.98px) {
  .testimonial-style2 .nav-link {
    padding-right: 0;
  }
}
@media (max-width: 575.98px) {
  .testimonial-style2 .nav-link img {
    max-height: 50px;
  }
}
@media (max-width: 339px) {
  .testimonial-style2 .nav-link img {
    max-width: 45px;
  }
}
.testimonial-style2 .nav-link.active {
  background-color: transparent;
}
.testimonial-style2 .nav-link.active img {
  border-radius: 50%;
  outline: 2px solid #5bbb7b;
  outline-offset: 10px;
}
.testimonial-style2.at-about2 .testi-text {
  margin: 0 auto 55px;
  max-width: 922px;
}
@media (max-width: 991.98px) {
  .testimonial-style2.at-about2 .testi-text {
    font-size: 20px;
    line-height: inherit;
  }
}
.testimonial-style2.at-home12 .icon {
  color: #f0efec;
}
.testimonial-style2.at-home12 .nav-link.active {
  background-color: transparent;
}
.testimonial-style2.at-home12 .nav-link.active img {
  border-radius: 50%;
  outline: 2px solid #222222;
  outline-offset: 10px;
}

.testimonial-style3 {
  background-color: #ffffff;
  outline: 1px solid #e9e9e9;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 60px 60px 55px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 991.98px) {
  .testimonial-style3 {
    padding: 40px 30px 35px;
  }
}
.testimonial-style3 .icon {
  color: #f7f7f7;
  font-size: 100px;
  line-height: initial;
  position: absolute;
  right: 60px;
  top: 15px;
}
.testimonial-style3 .text {
  font-size: 15px;
}
.testimonial-style3:before {
  background-color: #5bbb7b;
  bottom: 0;
  content: "";
  height: 12px;
  position: absolute;
  left: 50%;
  width: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.testimonial-style3:hover:before {
  left: 0;
  right: 0;
  width: 100%;
}

.testimonial-style4 {
  padding: 55px 0 30px 70px;
}
@media (max-width: 1399.98px) {
  .testimonial-style4 {
    padding: 25px 0 30px 40px;
  }
}
@media (max-width: 991.98px) {
  .testimonial-style4 {
    padding: 25px 0 30px 0;
  }
}
.testimonial-style4 .title {
  line-height: 35px;
}
.testimonial-style4 .desig {
  color: #697488;
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0em;
}

/*	Team Styles  */
.team-style1 {
  position: relative;
}
.team-style1 .team-img {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.team-style1 img {
  transform: scale(1);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.team-style1:hover img {
  transform: scale(1.1) rotate(-1deg);
}

.agency-style1 {
  position: relative;
}
.agency-style1 .tag {
  background-color: #5bbb7b;
  border-radius: 6px;
  color: #ffffff;
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 12px;
  left: 20px;
  padding: 0 6px;
  position: absolute;
  top: 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.agency-style1:hover .tag {
  transform: translateY(30px);
  opacity: 0;
}

.freelancer-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.freelancer-style1 .meta {
  color: var(--headings-color);
}
.freelancer-style1.about-page-style {
  margin-left: 280px;
  margin-top: -270px;
  z-index: 1;
  max-width: 329px;
}
@media (max-width: 1199.98px) {
  .freelancer-style1.about-page-style {
    margin-left: 210px;
  }
}
@media (max-width: 767.98px) {
  .freelancer-style1.about-page-style {
    margin-left: 0;
    margin-top: 0;
  }
}
.freelancer-style1.service-single {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  max-width: inherit;
}
.freelancer-style1.hover-box-shadow {
  box-shadow: none;
}
.freelancer-style1.hover-box-shadow:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.freelancer-style1.at-home7:hover {
  border: 1px solid #222222;
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}

.tag {
  background-color: #ffede8;
  border-radius: 60px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  display: inline-block;
  padding: 5px 17px;
  font-style: normal;
  font-size: 13px;
  line-height: 28px;
}

.online,
.online2,
.online-badge2,
.online-badge,
.online-badges {
  background-color: #5bbb7b;
  border: 2px solid #ffffff;
  border-radius: 50%;
  height: 16px;
  position: absolute;
  right: 0;
  top: 8px;
  width: 16px;
}

.online2 {
  right: 10px;
}

.online-badge,
.online-badges {
  right: 3px;
  top: -9px;
  height: 9px;
  width: 9px;
}
@media (max-width: 575.98px) {
  .online-badge,
  .online-badges {
    right: 0;
  }
}

.online-badges {
  top: 0;
}

.online-badge2 {
  height: 9px;
  right: 0;
  width: 9px;
}

@media (max-width: 575.98px) {
  .freelancer-single-style .online {
    top: -30px;
  }
}

.job-list-style1 {
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.job-list-style1 .fav-icon {
  background-color: #ffffff;
  border-radius: 50%;
  color: #5bbb7b;
  font-size: 20px;
  height: 36px;
  line-height: 45px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 36px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.job-list-style1:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}
.job-list-style1:hover .fav-icon {
  background-color: #f1fcfa;
}
.job-list-style1.at-home7 {
  border-radius: 8px;
}
.job-list-style1.at-home7:hover {
  border: 1px solid #222222;
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}
.job-list-style1.at-dashboard:hover {
  box-shadow: none;
}

/*  Different Styles Of Animations  */
.scale-infini {
  animation-name: myanimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

@keyframes myanimation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.bounce-y {
  transform: translatey(0px);
  animation: bounceY 6s ease-in-out infinite;
}

@keyframes bounceY {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.bounce-x {
  transform: translatey(0px);
  animation: bounceX 6s ease-in-out infinite;
}

@keyframes bounceX {
  0% {
    transform: translatex(0px);
  }
  50% {
    transform: translatex(-20px);
  }
  100% {
    transform: translatex(0px);
  }
}
.spin-right {
  animation: spin-right 14s infinite linear;
}

@keyframes spin-right {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin-left {
  animation: spin-left 14s infinite linear;
}

@keyframes spin-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
/*	Different Accordion Styles	*/
.accordion-style1 {
  position: relative;
  margin-bottom: 30px;
}
.accordion-style1 .accordion-item {
  border: none;
  border-radius: 4px;
  overflow: hidden;
}
.accordion-style1 .accordion-header {
  border: none;
}
.accordion-style1 .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 17px;
  line-height: 50px;
  padding-bottom: 4px;
  padding-left: 40px;
  padding-right: 30px;
  padding: 30px 30px 4px 40px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.accordion-style1 .accordion-button:before {
  color: #041e42;
  content: "+";
  font-family: var(--icon-font-family);
  font-size: 16px;
  font-weight: bold;
  right: 40px;
  position: absolute;
}
.accordion-style1 .accordion-button:after {
  display: none;
}
.accordion-style1 .accordion-button.collapsed:before {
  content: "+";
}
.accordion-style1 .accordion-button:not(.collapsed) {
  background-color: #f1fcfa;
}
.accordion-style1 .accordion-button:not(.collapsed):before {
  content: "";
}
.accordion-style1 .accordion-body {
  background-color: #f1fcfa;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding: 15px 30px 35px 40px;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
.accordion-style1.faq-page .accordion-body {
  max-width: initial;
}
.accordion-style1.style2 .accordion-item {
  border: none;
  padding: 0;
}
.accordion-style1.style2 .accordion-item.active {
  box-shadow: none;
}
.accordion-style1.style2 .accordion-header {
  border: none;
  border-bottom: 1px solid #e9e9e9;
}
.accordion-style1.style2 .accordion-body {
  max-width: initial;
  padding: 0;
}

.feature-accordion {
  position: relative;
}
.feature-accordion .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  outline: none;
}

.agent-single-accordion .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 14px;
  text-decoration-line: underline;
}
.agent-single-accordion .accordion-button:after {
  display: none;
}

.accordion-style2 {
  position: relative;
  margin-bottom: 30px;
}
.accordion-style2 .accordion-item {
  padding: 0;
}
.accordion-style2 .accordion-item.active .accordion-button:before {
  content: "";
}
.accordion-style2 .accordion-header {
  border: none;
}
.accordion-style2 .accordion-button {
  background-color: transparent;
  box-shadow: none;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 17px;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.accordion-style2 .accordion-button:before {
  color: #041e42;
  content: "";
  font-family: var(--icon-font-family);
  font-size: 14px;
  font-weight: bold;
  right: 10px;
  position: absolute;
}
.accordion-style2 .accordion-button:after {
  display: none;
}
.accordion-style2 .accordion-body {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding: 20px 0 0;
}

/*	Different Styles of Alart	*/
.message-alart-style1 {
  position: relative;
}
.message-alart-style1 .alert {
  border-radius: 4px;
  font-family: var(--title-font-family);
  font-weight: 500;
  padding: 23px 30px;
  position: relative;
}
.message-alart-style1 .btn-close {
  cursor: pointer;
  right: 12px;
  top: 5px;
  font-size: 16px;
  opacity: 1;
  background: transparent;
}
.message-alart-style1 .alart_style_one {
  background-color: rgba(205, 233, 246, 0.5);
  color: #4780aa;
}
.message-alart-style1 .alart_style_one .btn-close {
  color: #4780aa;
}
.message-alart-style1 .alart_style_two {
  background-color: rgba(247, 243, 215, 0.5);
  color: #927238;
}
.message-alart-style1 .alart_style_two .btn-close {
  color: #927238;
}
.message-alart-style1 .alart_style_three {
  background-color: rgba(236, 200, 197, 0.5);
  color: #ab3331;
}
.message-alart-style1 .alart_style_three .btn-close {
  color: #ab3331;
}
.message-alart-style1 .alart_style_four {
  background-color: rgba(222, 242, 215, 0.5);
  color: #5b7052;
}
.message-alart-style1 .alart_style_four .btn-close {
  color: #5b7052;
}

.search-modal .modal-header {
  border: none;
  padding: 30px 30px 0;
}
.search-modal .btn-close {
  background: none;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  height: 40px;
  opacity: 1;
  padding: 0;
  text-align: center;
  width: 40px;
}
.search-modal .btn-close:focus {
  box-shadow: none;
  outline: none;
}
.search-modal .modal-content {
  background-color: transparent;
  border: none;
}
.search-modal .modal-body {
  padding: 15px 30px 30px;
}

.log-reg-form .hr_content {
  overflow: hidden;
  position: relative;
}
.log-reg-form .hr_top_text {
  background-color: #ffffff;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-size: 16px;
  height: 40px;
  left: 0;
  letter-spacing: 0em;
  line-height: 40px;
  margin: 0 auto;
  right: 0;
  text-align: center;
  top: -5px;
  width: 40px;
  position: absolute;
}

.modal-backdrop {
  z-index: 0;
}

/*	blockquote Styles	*/
.blockquote-style1 {
  background-color: #ffede8;
  border-left: 3px solid var(--headings-color);
  padding: 45px 60px 20px;
  position: relative;
}
.blockquote-style1 .fst-italic {
  max-width: 645px;
  width: 100%;
}

/*	All kind Of btns Styles  */
.ud-btn {
  border-radius: 4px;
  display: inline-block;
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0em;
  padding: 13px 35px;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.ud-btn i {
  margin-left: 10px;
  font-size: 16px;
  transform: rotate(-45deg);
}
.ud-btn:hover,
.ud-btn:focus,
.ud-btn:active {
  outline: none;
  box-shadow: none;
}
@media (max-width: 339px) {
  .ud-btn {
    padding: 13px 25px;
  }
}

.ud-btn2 {
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 15px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.ud-btn2 i {
  margin-left: 10px;
  font-size: 16px;
}
.ud-btn2:hover {
  outline: none;
}

.ud-btn3 {
  font-family: var(--title-font-family);
  font-weight: 600;
  font-size: 15px;
  position: relative;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.ud-btn3 i {
  margin-left: 10px;
  font-size: 16px;
  transform: rotate(-45deg);
}
.ud-btn3:hover {
  outline: none;
}

.btn-thm {
  background-color: #577799;
  border: 2px solid #577799;
  color: #ffffff;
}

.btn-dark {
  background-color: var(--headings-color);
  border: 1px solid var(--headings-color);
  color: #ffffff;
}

.double-border {
  border-width: 2px !important;
}
.double-border:hover {
  border-width: 2px;
}

.ud-btn:before,
.btn-gray:before,
.btn-dark:before,
.btn-thm:before,
.btn-light-thm:before,
.btn-light-white:before,
.btn-thm2:before,
.btn-home3:before,
.btn-thm3:before,
.btn-thm4:before,
.btn-thm-border:before,
.btn-dark-border:before,
.btn-white:before,
.btn-light-gray:before,
.btn-transparent:before,
.btn-transparent2:before {
  background-color: #577799;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  -webkit-transform: skew(50deg);
  -moz-transform: skew(50deg);
  -o-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transition: width 0.6s;
  -moz-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
  transform-origin: top left;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.ud-btn:hover,
.btn-gray:hover,
.btn-dark:hover,
.btn-thm:hover,
.btn-light-thm:hover,
.btn-light-white:hover,
.btn-thm2:hover,
.btn-home3:hover,
.btn-thm3:hover,
.btn-thm4:hover,
.btn-thm-border:hover,
.btn-dark-border:hover,
.btn-white:hover,
.btn-light-gray:hover,
.btn-transparent:hover,
.btn-transparent2:hover {
  color: #ffffff;
}
.ud-btn:hover:before,
.btn-gray:hover:before,
.btn-dark:hover:before,
.btn-thm:hover:before,
.btn-light-thm:hover:before,
.btn-light-white:hover:before,
.btn-thm2:hover:before,
.btn-home3:hover:before,
.btn-thm3:hover:before,
.btn-thm4:hover:before,
.btn-thm-border:hover:before,
.btn-dark-border:hover:before,
.btn-white:hover:before,
.btn-light-gray:hover:before,
.btn-transparent:hover:before,
.btn-transparent2:hover:before {
  height: 100%;
  width: 200%;
}

.btn-thm6:before {
  background-color: #ff4d00;

  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  -webkit-transform: skew(50deg);
  -moz-transform: skew(50deg);
  -o-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transition: width 0.6s;
  -moz-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
  transform-origin: top left;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btn-thm7:before {
  background-color: #48576e;
  border: 1px solid #48576e;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  -webkit-transform: skew(50deg);
  -moz-transform: skew(50deg);
  -o-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transition: width 0.6s;
  -moz-transition: width 0.6s;
  -o-transition: width 0.6s;
  transition: width 0.6s;
  transform-origin: top left;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.btn-gray {
  background-color: #f7f7f7;
  border: 1px solid transparent;
  color: var(--headings-color);
  font-size: 13px;
  font-weight: 400;
  height: 50px;
  padding: 10px 30px;
}

.btn-dark:hover {
  background-color: #48576e;
  border: 1px solid #48576e;
}

.btn-thm-border:hover {
  background-color: #577799;
  border: 2px solid #577799;
}

.btn-dark-border {
  background-color: #ffffff;
  border: 2px solid #222222;
  color: #222222;
}
.btn-dark-border:hover {
  background-color: #222222;
  border: 2px solid #222222;
}
.btn-dark-border:hover:before {
  background-color: #222222;
}

.btn-thm:hover {
  border: 2px solid #48576e;
}
.btn-thm:hover:before {
  background-color: #48576e;
}

.btn-light-thm {
  background-color: rgba(91, 187, 123, 0.1);
  color: #5bbb7b;
}

.btn-light-white {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.btn-thm2 {
  background-color: #48576e;
  border: none;
  color: #ffffff;
}
.btn-thm2:hover {
  background-color: #577799;
  border-color: #1f4b3f;
}

.btn-thm6 {
  background-color: #fc7d2d;
  border: none;
  color: #ffffff;
}
.btn-thm6:hover {
  background-color: #fc7d2d;
  border-color: #fc7d2d;
}

.btn-home3 {
  background-color: #397e62;
  border: 1px solid #397e62;
  color: #ffffff;
}

.btn-thm3 {
  background-color: #ffffff;
  border: 1px solid #5bbb7b;
  color: #5bbb7b;
}

.btn-thm4 {
  background-color: #266464;
  color: #ffffff;
}

.btn-white,
.btn-white2 {
  background-color: #ffffff;
  border: 2px solid #ffffff;
}
.btn-white:hover,
.btn-white2:hover {
  border: 2px solid #5bbb7b;
}

.btn-white2 {
  border: 2px solid var(--headings-color);
}
.btn-white2:before {
  background-color: #222222;
}
.btn-white2:hover {
  border: 2px solid #222222;
}

.btn-light-gray,
.btn-transparent {
  background-color: #f7f7f7;
  border: 1px solid var(--headings-color);
}
.btn-light-gray:hover,
.btn-transparent:hover {
  border: 1px solid #5bbb7b;
}

.btn-transparent,
.btn-transparent2 {
  background-color: transparent;
}

.btn-transparent2 {
  border: 1px solid #ffffff;
  color: #ffffff;
}
.btn-transparent2:hover,
.btn-transparent2:focus,
.btn-transparent2:active {
  border: 1px solid #ffffff;
  color: #5bbb7b;
}
.btn-transparent2:hover:before,
.btn-transparent2:focus:before,
.btn-transparent2:active:before {
  background-color: #ffffff;
}

.add-joining {
  font-size: 15px;
  font-family: var(--title-font-family);
  font-weight: 500;
  height: 40px;
  padding: 5px 30px;
}
.add-joining:hover {
  color: #ffffff;
}
.add-joining.at-home10 {
  border-width: 2px;
}
.add-joining.at-home10:hover {
  border-width: 2px;
}

.menu-btn {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.menu-btn:hover {
  background-color: #5bbb7b;
  border: 1px solid #5bbb7b;
}

.search-modal .btn-google,
.search-modal .btn-fb,
.search-modal .btn-apple {
  padding-left: 38px;
}
.search-modal .btn-google i,
.search-modal .btn-fb i,
.search-modal .btn-apple i {
  font-size: 14px;
  margin-left: 0;
  left: 18px;
  transform: rotate(0deg);
  position: absolute;
  top: 12px;
}

.btn-fb {
  background-color: #ffffff;
  border: 1px solid #3a77ea;
  color: #3a77ea;
}
.btn-fb:hover {
  border-color: #5bbb7b;
}

.btn-google {
  background-color: #ffffff;
  border: 1px solid #d93025;
  color: #d93025;
}
.btn-google:hover {
  border-color: #5bbb7b;
}

.btn-apple {
  background-color: #ffffff;
  border: 1px solid #041e42;
  color: #041e42;
}
.btn-apple:hover {
  border-color: #5bbb7b;
}

.radio-element {
  position: relative;
}
.radio-element .form-check-input {
  border: 1px solid #1f4b3f;
  height: 16px;
  margin-top: 0;
  width: 16px;
}
.radio-element .form-check-input:focus {
  border: 1px solid #1f4b3f;
  box-shadow: none;
  outline: none;
}
.radio-element .form-check-input:checked {
  background-color: #1f4b3f;
}
.radio-element .form-check-label {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
}

.right-tags {
  color: var(--body-text-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  position: absolute;
  right: 0;
}

.checkbox-style1 {
  position: relative;
}

.custom_checkbox {
  display: block;
  cursor: pointer;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
  letter-spacing: 0em;
  position: relative;
  padding-left: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom_checkbox input:checked ~ .checkmark {
  background-color: #1f4b3f;
}
.custom_checkbox .checkmark {
  border: 1px solid #041e42;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  left: 0;
  height: 16px;
  width: 16px;
}
.custom_checkbox .checkmark:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.switch-style1 input.form-check-input {
  background-color: rgba(91, 187, 123, 0.1);
  border: none;
  height: 30px;
  width: 55px;
}
.switch-style1 .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  transition: background-position 0.15s ease-in-out;
}
.switch-style1 .form-check-input:checked {
  background-color: #5bbb7b;
  box-shadow: none;
}
.switch-style1 .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  box-shadow: none;
  outline: none;
}
.switch-style1 label.form-check-label {
  color: var(--headings-color);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 30px;
  margin-left: 17px;
}

.bselect-style1 .bootstrap-select .dropdown-toggle {
  border: none;
  font-size: 15px;
  height: 55px;
  line-height: 40px;
  outline: none;
}
.bselect-style1 .bootstrap-select .dropdown-toggle:hover {
  color: var(--headings-color);
}
.bselect-style1 .bootstrap-select .dropdown-toggle:focus {
  border: none;
  outline-offset: 0;
}
.bselect-style1 .bootstrap-select .dropdown-menu {
  margin-top: 13px !important;
  padding-bottom: 7px;
}
.bselect-style1 .bootstrap-select .dropdown-menu.show {
  border: 1px solid #e9e9e9;
}
.bselect-style1 .bootstrap-select .dropdown-menu.inner.show {
  border: none;
}
.bselect-style1 .bootstrap-select .dropdown-item {
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
}
.bselect-style1 .bootstrap-select .dropdown-item:hover,
.bselect-style1 .bootstrap-select .dropdown-item.active {
  background-color: #f0efec;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn,
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn-light {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .dropdown-toggle:focus {
  box-shadow: none;
  outline: none !important;
}

.form-style1
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}
.form-style1
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn,
.form-style1
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn-light {
  border: 1px solid #e9e9e9;
}

.bootstrap-select.show-tick .dropdown-menu .selected span {
  color: var(--headings-color);
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  right: 25px;
}

.video-icon {
  background-color: #ffffff;
  border-radius: 50%;
  color: var(--headings-color);
  display: block;
  height: 54px;
  line-height: 54px;
  outline: 1px solid #ffffff;
  outline-offset: 7px;
  text-align: center;
  width: 54px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.video-icon:hover {
  background-color: #5bbb7b;
  color: #ffffff;
  outline-offset: 10px;
  outline: 1px solid #ffffff;
}

.video-btn {
  background-color: #ffffff;
  border-radius: 50%;
  color: var(--headings-color);
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.add-more-btn .icon {
  background-color: #ffede8;
  border-radius: 50%;
  color: var(--headings-color);
  font-size: 10px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
}

/*  Funfact Styles  */
.funfact-floating-img1 {
  bottom: 0;
  position: absolute;
  right: 0;
}

.funfact-floating-img2 {
  position: absolute;
  left: 0;
  top: 0;
}

.funfact_one {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.funfact_one .timer,
.funfact_one span {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 38px;
  line-height: 49px;
}
.funfact_one.at-home2-hero .timer,
.funfact_one.at-home2-hero span {
  color: #ffffff;
  font-size: 28px;
}
.funfact_one.at-home5 {
  border-radius: 12px;
  padding: 50px 0;
}
.funfact_one.at-home5:hover {
  background-color: #ffffff;
}
.funfact_one.at-home5 .icon,
.funfact_one.at-home5 .timer,
.funfact_one.at-home5 span {
  color: #1f4b3f;
}
.funfact_one.at-home5-hero .timer,
.funfact_one.at-home5-hero span {
  color: #1f4b3f;
  font-size: 28px;
}

.funfact-style1 {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  max-width: 280px;
  padding: 60px 15px 35px;
  position: relative;
}
@media (max-width: 991.98px) {
  .funfact-style1 {
    padding: 30px 15px 25px;
  }
}
@media (max-width: 575.98px) {
  .funfact-style1 {
    max-width: initial;
  }
}
.funfact-style1 .title,
.funfact-style1 span {
  color: var(--headings-color);
  font-family: var(--body-font-family);
  font-size: 45px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
}
@media (max-width: 991.98px) {
  .funfact-style1 .title,
  .funfact-style1 span {
    font-size: 35px;
  }
}
.funfact-style1.at-home5 {
  padding: 40px 15px 30px;
}

.home11-funfact {
  background-image: url(./images/background/home11-cta-1.png);
  background-size: cover;
}

/*	Menu Style  */
header.nav-innerpage-style {
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  padding: 7px 0;
}
header.nav-innerpage-style .ace-responsive-menu .sub-menu {
  background-color: #ffffff;
  border-radius: 12px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 15px 0;
  z-index: 9999;
  width: 260px;
}
header.nav-innerpage-style .ace-responsive-menu .sub-menu li {
  border-left: 2px solid transparent;
  padding: 0 30px;
  width: 260px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.nav-innerpage-style .ace-responsive-menu .sub-menu li:hover {
  background-color: #f0efec;
  border-left: 2px solid #5bbb7b;
}
header.nav-innerpage-style .ace-responsive-menu .sub-menu a {
  line-height: 40px;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  .mega_menu_list {
  padding-left: 0;
}
header.nav-innerpage-style .ace-responsive-menu .megamenu_style .sub-menu li {
  padding-left: 0;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  .sub-menu
  li:hover {
  background-color: transparent;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  .sub-menu
  a:hover {
  text-decoration: underline;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style:hover
  .dropdown-megamenu {
  border-radius: 12px;
  left: auto;
  max-width: 600px;
  overflow: hidden;
  padding: 30px;
  width: 100%;
  z-index: 99999;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  .dropdown-megamenu
  .sub-menu {
  border: none;
  box-shadow: none;
  display: block !important;
  float: left;
  left: auto !important;
  margin: 0;
  padding: 0;
  position: relative;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  .mega_menu_list {
  float: left;
  width: 33.33%;
}
header.nav-innerpage-style .ace-responsive-menu .megamenu_style a.list-item,
header.nav-innerpage-style .ace-responsive-menu .visible_list a.list-item {
  border-radius: 60px;
  text-align: center;
  padding: 7px 13px;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover,
header.nav-innerpage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:hover {
  color: #5bbb7b;
}
@media (max-width: 1399.98px) {
  header.nav-innerpage-style .ace-responsive-menu .megamenu_style a.list-item,
  header.nav-innerpage-style .ace-responsive-menu .visible_list a.list-item {
    padding: 7px 11px;
  }
}
@media (max-width: 1199.98px) {
  header.nav-innerpage-style .ace-responsive-menu .megamenu_style a.list-item,
  header.nav-innerpage-style .ace-responsive-menu .visible_list a.list-item {
    padding: 7px 8px;
  }
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover,
header.nav-innerpage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:hover {
  background-color: transparent;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover:before,
header.nav-innerpage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:hover:before {
  width: 100%;
}
header.nav-innerpage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:before,
header.nav-innerpage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:before {
  background-color: #5bbb7b;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: -18px;
  width: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.nav-innerpage-style .ace-responsive-menu a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
header.nav-innerpage-style.menu-home4 {
  box-shadow: none;
  border-bottom: none;
  padding: 18px 0;
}
header.nav-innerpage-style.at-home3 {
  background-color: #fff8f6;
}
header.nav-innerpage-style.style2 {
  box-shadow: none;
}
header.menu-home10 {
  box-shadow: none;
  border-bottom: none;
  padding: 0;
}
header.menu-home10 .ace-responsive-menu .sub-menu {
  margin-top: 1px;
}
header.menu-home10 .ace-responsive-menu .megamenu_style a.list-item,
header.menu-home10 .ace-responsive-menu .visible_list a.list-item {
  border-radius: 0px;
  line-height: 72px;
  position: relative;
}
header.menu-home10 .ace-responsive-menu .megamenu_style a.list-item:hover,
header.menu-home10 .ace-responsive-menu .visible_list a.list-item:hover {
  background-color: transparent;
}
header.menu-home10
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover:before,
header.menu-home10 .ace-responsive-menu .visible_list a.list-item:hover:before {
  width: 100%;
}
header.menu-home10 .ace-responsive-menu .megamenu_style a.list-item:before,
header.menu-home10 .ace-responsive-menu .visible_list a.list-item:before {
  background-color: #5bbb7b;
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  width: 0;
  top: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.menu-home10.stricky.stricky-fixed
  .ace-responsive-menu
  .megamenu_style
  a.list-item:before,
header.menu-home10.stricky.stricky-fixed
  .ace-responsive-menu
  .visible_list
  a.list-item:before {
  background-color: #222222;
}
header.nav-homepage-style {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  padding: 7px 0;
  position: fixed;
  width: 100%;
  z-index: 3;
}
header.nav-homepage-style .ace-responsive-menu .sub-menu {
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 15px 0;
  z-index: 9999;
  width: 260px;
}
header.nav-homepage-style .ace-responsive-menu .sub-menu li {
  border-left: 2px solid transparent;
  padding: 0 30px;
  width: 260px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.nav-homepage-style .ace-responsive-menu .sub-menu li:hover {
  background-color: #f0efec;
  border-left: 2px solid #5bbb7b;
}
header.nav-homepage-style .ace-responsive-menu .sub-menu a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  line-height: 40px;
}
header.nav-homepage-style .ace-responsive-menu .megamenu_style .mega_menu_list {
  padding-left: 0;
}
header.nav-homepage-style .ace-responsive-menu .megamenu_style .sub-menu li {
  padding-left: 0;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  .sub-menu
  li:hover {
  background-color: transparent;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  .sub-menu
  a:hover {
  text-decoration: underline;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style:hover
  .dropdown-megamenu {
  border-radius: 4px;
  left: auto;
  max-width: 600px;
  overflow: hidden;
  padding: 30px;
  width: 100%;
  z-index: 99999;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  .dropdown-megamenu
  .sub-menu {
  border: none;
  box-shadow: none;
  display: block !important;
  float: left;
  left: auto !important;
  margin: 0;
  padding: 0;
  position: relative;
}
header.nav-homepage-style .ace-responsive-menu .megamenu_style .mega_menu_list {
  float: left;
  width: 33.33%;
}
header.nav-homepage-style .ace-responsive-menu .megamenu_style a.list-item,
header.nav-homepage-style .ace-responsive-menu .visible_list a.list-item {
  border-radius: 60px;
  text-align: center;
  padding: 7px 14px;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover,
header.nav-homepage-style .ace-responsive-menu .visible_list a.list-item:hover {
  background-color: rgba(255, 255, 255, 0.07);
}
@media (max-width: 1399.98px) {
  header.nav-homepage-style .ace-responsive-menu .megamenu_style a.list-item,
  header.nav-homepage-style .ace-responsive-menu .visible_list a.list-item {
    padding: 7px 9px;
  }
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover,
header.nav-homepage-style .ace-responsive-menu .visible_list a.list-item:hover {
  background-color: transparent;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover:before,
header.nav-homepage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:hover:before {
  width: 100%;
}
header.nav-homepage-style
  .ace-responsive-menu
  .megamenu_style
  a.list-item:before,
header.nav-homepage-style
  .ace-responsive-menu
  .visible_list
  a.list-item:before {
  background-color: #5bbb7b;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  top: -18px;
  width: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.nav-homepage-style .ace-responsive-menu a {
  color: #ffffff;
  font-family: var(--title-font-family);
  font-weight: 600;
}
header.nav-homepage-style .header-logo.logo2 {
  display: none;
}
header.nav-homepage-style .btn-mega {
  color: #ffffff;
}
header.nav-homepage-style .sidemenu-btn,
header.nav-homepage-style .login-info {
  color: #ffffff;
}
header.nav-homepage-style.stricky.stricky-fixed {
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9e9;
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  z-index: 9;
  top: 0;
}
header.nav-homepage-style.stricky.stricky-fixed .ace-responsive-menu a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
header.nav-homepage-style.stricky.stricky-fixed .sidemenu-btn,
header.nav-homepage-style.stricky.stricky-fixed .login-info,
header.nav-homepage-style.stricky.stricky-fixed .btn-mega {
  color: var(--headings-color);
}
header.nav-homepage-style.stricky.stricky-fixed .header-logo.logo1 {
  display: none;
}
header.nav-homepage-style.stricky.stricky-fixed .header-logo.logo2 {
  display: block;
}
header.nav-homepage-style.stricky.stricky-fixed .menu-btn,
header.nav-homepage-style.stricky.stricky-fixed .btn-transparent2 {
  border: 1px solid var(--headings-color);
  color: var(--headings-color);
}
header.nav-homepage-style.stricky.stricky-fixed .menu-btn:hover {
  background-color: #5bbb7b;
  border: 1px solid #5bbb7b;
}
header.nav-homepage-style.at-home2,
header.nav-homepage-style.at-home5 {
  border-bottom: none;
}
header.nav-homepage-style.at-home3 .ace-responsive-menu a {
  color: var(--headings-color);
}
header.nav-homepage-style.at-home3 .sidemenu-btn,
header.nav-homepage-style.at-home3 .login-info {
  color: var(--headings-color);
}
header.nav-homepage-style.at-home5 .ace-responsive-menu .sub-menu {
  margin-top: 1px;
}
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .megamenu_style
  a.list-item,
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .visible_list
  a.list-item {
  border-radius: 0px;
  position: relative;
}
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover,
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .visible_list
  a.list-item:hover {
  background-color: transparent;
}
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .megamenu_style
  a.list-item:hover:before,
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .visible_list
  a.list-item:hover:before {
  width: 100%;
}
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .megamenu_style
  a.list-item:before,
header.nav-homepage-style.at-home5
  .ace-responsive-menu
  .visible_list
  a.list-item:before {
  background-color: #ffffff;
  bottom: 0;
  content: "";
  position: absolute;
  height: 2px;
  left: 0;
  width: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
header.nav-homepage-style.at-home5.stricky.stricky-fixed
  .ace-responsive-menu
  .megamenu_style
  a.list-item:before,
header.nav-homepage-style.at-home5.stricky.stricky-fixed
  .ace-responsive-menu
  .visible_list
  a.list-item:before {
  background-color: #222222;
}
/*== Mobile Menu Css ==*/
.header.innerpage-style {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  height: 65px;
  line-height: 65px;
}

.mm-panel {
  background-color: #ffffff;
  background: #ffffff;
}

.mm-listitem:after {
  display: none;
}

.mm-navbar {
  background-color: rgba(91, 187, 123, 0.07);
  color: var(--headings-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  height: 60px;
}

.mm-navbar__title {
  color: var(--headings-color);
}

.mm-wrapper__blocker {
  background-color: rgba(34, 34, 34, 0.5);
}

.mm-panel ul.mm-listview li:first-child.mm-listitem {
  margin-top: 30px;
}

.mm-panel ul.mm-listview li.mm-listitem.cl_btn {
  border-bottom: none;
  margin: 50px auto;
  max-width: 90%;
}

.mm-panel ul.mm-listview li.mm-listitem.cl_btn a.btn {
  color: #ffffff;
  font-family: var(--title-font-family);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

a.mm-listitem__text,
a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text {
  color: var(--headings-color);
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

a.mm-listitem__text:hover,
a.mm-btn.mm-btn_next.mm-listitem__btn.mm-listitem__text:hover {
  background-color: rgba(91, 187, 123, 0.07);
  border-left: 2px solid #5bbb7b;
  color: #5bbb7b;
}

li.mm-listitem:hover,
li.mm-listitem:active,
li.mm-listitem:focus {
  color: #ffffff;
}

.mm-btn_prev:before {
  background-color: transparent;
}

.hsidebar-content {
  padding: 30px 0;
  position: relative;
}

.modal-lg {
  max-width: 662px;
}

.modal:before {
  background: rgba(34, 34, 34, 0.7);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.hiddenbar-body-ovelay {
  background: rgba(34, 34, 34, 0.7);
  height: 100%;
  opacity: 0;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  visibility: hidden;
  z-index: 9990;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-hidden-sidebar-content .hiddenbar-body-ovelay {
  opacity: 1;
  visibility: visible;
}
.menu-hidden-sidebar-content .lefttside-hidden-bar {
  opacity: 1;
  left: 0px;
  visibility: visible;
}

.signin-hidden-sidebar-content .rightside-hidden-bar {
  opacity: 1;
  right: 0px;
  visibility: visible;
}
.signin-hidden-sidebar-content .hiddenbar-body-ovelay {
  opacity: 1;
  visibility: visible;
}

.rightside-hidden-bar {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  right: -400px;
  top: 0px;
  width: 400px;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 575.98px) {
  .rightside-hidden-bar {
    max-width: 300px;
  }
}
.rightside-hidden-bar .hsidebar-header {
  border-bottom: 1px solid #e9e9e9;
  padding: 25px 20px 15px 30px;
  display: block;
  position: relative;
}
.rightside-hidden-bar .hsidebar-header .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.rightside-hidden-bar .hsidebar-header .sidebar-close-icon {
  background-color: #f7f7f7;
  border-radius: 50%;
  cursor: pointer;
  color: var(--headings-color);
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: 20px;
  width: 40px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 575.98px) {
  .rightside-hidden-bar .hsidebar-header .sidebar-close-icon {
    right: 10px;
  }
}
.rightside-hidden-bar::-webkit-scrollbar {
  width: 8px;
}
.rightside-hidden-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 12px;
}
.rightside-hidden-bar::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 12px;
}

.lefttside-hidden-bar {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  height: 100%;
  opacity: 0;
  position: fixed;
  right: -360px;
  top: 0px;
  visibility: hidden;
  width: 360px;
  z-index: 99999;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.lefttside-hidden-bar .hsidebar-header {
  padding: 25px 20px 25px 30px;
  display: block;
  position: relative;
}
.lefttside-hidden-bar .sidebar-close-icon {
  background-color: #f1fcfa;
  border-radius: 8px;
  cursor: pointer;
  color: var(--headings-color);
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 20px;
  width: 40px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media (max-width: 400px) {
  .lefttside-hidden-bar .sidebar-close-icon {
    right: 30px;
  }
}
.lefttside-hidden-bar:-webkit-scrollbar {
  width: 3px;
}
.lefttside-hidden-bar .hsidebar-content {
  padding: 0 30px 30px;
  position: relative;
  max-height: 750px;
  overflow-y: scroll;
}
.lefttside-hidden-bar .hsidebar-content::-webkit-scrollbar {
  background: transparent;
  border-radius: 10px;
  padding-right: 10px;
  width: 10px;
}
.lefttside-hidden-bar .hsidebar-content::-webkit-scrollbar-thumb {
  background-color: #f1fcfa;
  border-radius: 10px;
}
.lefttside-hidden-bar.map-page .hsidebar-content {
  max-height: 900px;
}

.lefttside-hidden-bar {
  left: -350px;
  right: auto;
  width: 350px;
}
@media (max-width: 400px) {
  .lefttside-hidden-bar {
    width: 300px;
  }
}

.hiddenbar_navbar_menu {
  margin-bottom: 200px;
  padding-top: 30px;
  position: relative;
}
.hiddenbar_navbar_menu .navbar-nav .nav-item {
  padding-left: 2px solid transparent;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link {
  border-left: 2px solid transparent;
  line-height: 51px;
  letter-spacing: 0em;
  position: relative;
  padding: 0 30px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:hover,
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:active,
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:focus {
  background-color: rgba(91, 187, 123, 0.07);
  border-left: 2px solid #5bbb7b;
  color: #5bbb7b;
}
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link i {
  line-height: 60px;
  position: absolute;
  right: 30px;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link.collapsed i {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.body_content_wrapper:before {
  background: rgba(4, 30, 66, 0.5);
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.body_content_wrapper.before_active:before {
  opacity: 1;
  z-index: 9;
}

.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:hover i,
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:active i,
.hiddenbar_navbar_menu .navbar-nav .nav-item .nav-link:focus i {
  color: #443297;
}

.hiddenbar_navbar_menu
  .navbar-nav
  .nav-item
  .collapse
  .nav
  .nav-item
  .nav-link:after {
  display: none;
}

.mobile_menu_close_btn {
  background-color: #f7f7f7;
  border-radius: 8px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  cursor: pointer;
  color: var(--headings-color);
  font-size: 10px;
  height: 40px;
  left: -15px;
  line-height: 40px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 15px;
  width: 40px;
  z-index: -1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.advance-search-menu {
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
}
.advance-search-menu.style2 {
  background-color: #222222;
}

.advance-search-list li .search-field {
  border-radius: 40px;
  height: 44px;
  padding-left: 20px;
  width: 470px;
}
@media (max-width: 767.98px) {
  .advance-search-list li .search-field {
    margin-bottom: 15px;
    width: auto;
  }
}
.advance-search-list li:last-child .open-btn {
  background-color: #f7f7f7;
  border: 1px solid #222222;
}
.advance-search-list li .open-btn {
  border: 1px solid #e9e9e9;
}
.advance-search-list.no-box-shadow .open-btn {
  box-shadow: none;
}

/*button Mega Menu Css Code*/
/* Menu Mega Code Start */
#mega-menu {
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  max-width: 150px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1199.98px) {
  #mega-menu {
    max-width: 120px;
  }
}
#mega-menu .menu {
  border-radius: 4px 0 0 4px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  opacity: 0;
  position: relative;
  top: 20px;
  z-index: 90;
  width: 330px;
  visibility: hidden;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1199.98px) {
  #mega-menu .menu {
    width: 310px;
  }
}
#mega-menu .menu li {
  background-color: #ffffff;
  border-left: 2px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mega-menu .menu li:hover {
  background-color: #f0efec;
  border-left: 2px solid #5bbb7b;
}
#mega-menu .menu li:hover .drop-menu {
  opacity: 1;
  visibility: visible;
  transform: translateX(1.8%);
}
#mega-menu .menu li a {
  border-left: 2px solid transparent;
  padding: 5px 20px;
  display: block;
  position: relative;
}
@media (max-width: 1199.98px) {
  #mega-menu .menu li a {
    padding: 5px 15px;
  }
}
#mega-menu .menu li a.dropdown:after {
  content: "";
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  right: 30px;
  font-size: 14px;
  color: var(--headings-color);
}
#mega-menu .menu li:first-child {
  border-radius: 4px 0 0 0;
}
#mega-menu .menu li:last-child {
  border-radius: 0 0 0 4px;
}
#mega-menu .menu .menu-icn {
  color: #1f4b3f;
  font-size: 22px;
  margin-right: 8px;
  min-width: 30px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
#mega-menu .menu .menu-title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 50px;
}
#mega-menu .drop-menu {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 0 4px 4px 0;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  height: 493px;
  opacity: 0;
  visibility: hidden;
  padding: 30px;
  position: absolute;
  top: -1px;
  left: 316px;
  width: 650px;
  z-index: -1;
  transform: translateX(-1%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1199.98px) {
  #mega-menu .drop-menu {
    left: 298px;
    width: 530px;
  }
}
#mega-menu .drop-menu .cat-title {
  color: var(--headings-color);
  margin-bottom: 10px;
}
#mega-menu .drop-menu li {
  background-color: transparent;
}
#mega-menu .drop-menu li:hover {
  background-color: transparent;
  border-color: transparent;
}
#mega-menu .drop-menu li a {
  border-bottom: none;
  color: var(--body-text-color);
  font-size: 14px;
  font-family: var(--title-font-family);
  font-weight: 400;
  line-height: 32px;
  padding: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#mega-menu .drop-menu li a:hover {
  color: var(--headings-color);
}
#mega-menu:hover .menu {
  opacity: 1;
  top: 10px;
  visibility: visible;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.home1_style.at-home2 #mega-menu {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  margin-left: 15px;
}

/* Menu Mega Code End */
/*	Nav & Tabs Styles  */
.navpill-style1 {
  position: relative;
}
.navpill-style1 .nav-item {
  margin-right: 10px;
}
.navpill-style1 .nav-item:last-child {
  margin-right: 0;
}
.navpill-style1 .nav-link {
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 7px 17px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navpill-style1 .nav-link.active,
.navpill-style1 .nav-link:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 32px;
  border: 1px solid #e9e9e9;
  color: #5bbb7b;
}
.navpill-style1.payout-style .nav-pills {
  background-color: #ffede8;
  border-radius: 60px;
  height: 45px;
  width: 350px;
}
.navpill-style1.payout-style .nav-pills .nav-link {
  color: #1f4b3f;
  font-size: 14px;
  padding: 4px 17px;
}
.navpill-style1.payout-style .nav-pills .nav-link:hover,
.navpill-style1.payout-style .nav-pills .nav-link.active {
  color: var(--headings-color);
  height: 35px;
  line-height: initial;
}

.navpill-style2 {
  position: relative;
}
.navpill-style2 .nav-item {
  margin-right: 10px;
}
.navpill-style2 .nav-item:last-child {
  margin-right: 0;
}
.navpill-style2 .nav-link {
  border: 1px solid transparent;
  border-radius: 6px;
  color: var(--body-text-color);
  padding: 7px 17px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navpill-style2 .nav-link.active,
.navpill-style2 .nav-link:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  color: var(--headings-color);
}
.navpill-style2.at-home6 .nav-item {
  margin-right: 0;
}
.navpill-style2.at-home6 .nav-link.active,
.navpill-style2.at-home6 .nav-link:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  color: #5bbb7b;
}
.navpill-style2.at-home9 .nav-item {
  margin-right: 0;
}
.navpill-style2.at-home9 .nav-link.active,
.navpill-style2.at-home9 .nav-link:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 32px;
  border: 1px solid #e9e9e9;
  color: #5bbb7b;
}

.navtab-style1,
.navtab-style2 {
  position: relative;
}
.navtab-style1 .nav-tabs,
.navtab-style2 .nav-tabs {
  border-bottom: 1px solid #e9e9e9;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navtab-style1 .nav-link,
.navtab-style2 .nav-link {
  border: 2px solid transparent;
  color: #697488;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navtab-style1 .nav-link:hover,
.navtab-style2 .nav-link:hover {
  border: 2px solid transparent;
}
.navtab-style1 .nav-link.active,
.navtab-style2 .nav-link.active {
  border: 2px solid transparent;
  border-bottom: 2px solid var(--headings-color);
  color: #222222;
}

.navtab-style1.blog-page-style {
  overflow-x: auto;
}
.navtab-style1.blog-page-style nav {
  width: 1400px;
}

.navtab-style2 .nav-tabs {
  border-bottom: none;
}

.dark-light-navtab {
  position: relative;
}
.dark-light-navtab .nav-link {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid var(--headings-color);
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  padding: 6px 17px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.dark-light-navtab .nav-link.active {
  background-color: #ffffff;
  border: 1px solid var(--headings-color);
  color: var(--headings-color);
}
.dark-light-navtab.style2 .nav-item:last-child .nav-link {
  margin-right: 0;
}
.dark-light-navtab.style2 .nav-link {
  color: var(--headings-color);
}
.dark-light-navtab.style2 .nav-link.active {
  background-color: var(--headings-color);
  color: #ffffff;
}
.dark-light-navtab.style3 .nav-link {
  background-color: rgba(255, 255, 255, 0.07);
  border: none;
  color: #ffffff;
}
.dark-light-navtab.style3 .nav-link.active {
  background-color: #ffffff;
  border: none;
  color: #5bbb7b;
}
.dark-light-navtab.style4 .nav-link {
  border: none;
  border-radius: 0;
  color: var(--headings-color);
  margin-right: 0;
}
.dark-light-navtab.style4 .nav-link.active {
  border-bottom: 2px solid var(--headings-color);
}

.tab-content {
  height: auto;
}
.tab-content .active {
  height: auto;
}

.ps-navtab .nav-link {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 40px;
}
.ps-navtab .nav-link.active {
  background-color: #f7f7f7;
  border: 1px solid var(--headings-color);
  color: var(--headings-color);
}

.ps-v4-hero-tab .nav-pills {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 9;
}
.ps-v4-hero-tab .nav-link {
  background-color: rgba(34, 34, 34, 0.8);
  border-radius: 4px;
  height: 54px;
  line-height: 60px;
  padding: 0;
  width: 54px;
}
.ps-v4-hero-tab .nav-link.active {
  background-color: #5bbb7b;
}
.ps-v4-hero-tab.at-v5 .nav-pills {
  right: 0;
}
.ps-v4-hero-tab.at-v5 .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
}
.ps-v4-hero-tab.at-v5 .nav-link.active {
  background-color: #5bbb7b;
}

.terms_condition_widget .nav-tabs {
  border-bottom: none;
}
.terms_condition_widget .nav-link {
  border: none;
  border-left: 1px solid #e9e9e9;
  border-radius: 0;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 45px;
  padding: 0 0 0 20px;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.terms_condition_widget .nav-link.active {
  border-left: 2px solid #5bbb7b;
  color: #5bbb7b;
}

.vertical-tab .nav-tabs {
  border-bottom: none;
}
.vertical-tab .nav-link {
  border: none;
  border-radius: 0;
  color: var(--body-text-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 25px;
  padding: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.vertical-tab .nav-link span {
  border-bottom: 2px solid transparent;
  padding-bottom: 10px;
}
.vertical-tab .nav-link.active span {
  border-bottom: 2px solid #222222;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}

.home2_testimonial_tabs .thumb {
  padding: 10px;
}
.home2_testimonial_tabs img {
  width: 70px;
}
@media (max-width: 991.98px) {
  .home2_testimonial_tabs img {
    width: 40px;
  }
}
.home2_testimonial_tabs .title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}
.home2_testimonial_tabs .title small {
  color: var(--body-text-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
}
.home2_testimonial_tabs .nav-link {
  border: 1px solid #e9e9e9;
  border-radius: 100px;
  margin: 0 15px;
  padding: 0;
  width: 350px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 1399.98px) {
  .home2_testimonial_tabs .nav-link {
    margin-bottom: 10px;
  }
}
@media (max-width: 1199.98px) {
  .home2_testimonial_tabs .nav-link {
    max-width: initial;
  }
}
@media (max-width: 1399.98px) {
  .home2_testimonial_tabs .nav-link {
    margin: 0 5px 10px;
    width: 250px;
  }
}
@media (max-width: 991.98px) {
  .home2_testimonial_tabs .nav-link {
    width: 220px;
  }
}
.home2_testimonial_tabs .nav-link.active {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.extra-service-tab .nav-tabs {
  border-bottom: none;
}
.extra-service-tab .nav-link {
  border: 2px solid transparent;
  outline: 1px solid #e9e9e9;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 26px 30px 35px;
  position: relative;
}
.extra-service-tab .nav-link.active {
  border: 2px solid #1f4b3f;
}
.extra-service-tab .text-bottom {
  display: block;
  line-height: initial;
}
.extra-service-tab .price {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  right: 30px;
  top: 45px;
}
@media (max-width: 575.98px) {
  .extra-service-tab .price {
    right: 20px;
    top: 10px;
  }
}

.home9-navtab-style .nav-link {
  border-bottom: none;
  color: rgba(34, 34, 34, 0.3);
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  padding-top: 0;
  padding-left: 0;
  margin-right: 40px;
}
.home9-navtab-style .nav-link:last-child {
  margin-right: 0;
}
.home9-navtab-style .nav-link.active,
.home9-navtab-style .nav-link:hover,
.home9-navtab-style .nav-link:focus,
.home9-navtab-style .nav-link:visited {
  color: #222222;
  border-bottom: none;
}

/*  Paginations Styles  */
.mbp_pagination ul {
  margin: 0;
  padding: 0;
}
.mbp_pagination .page_navigation {
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
.mbp_pagination .page-item {
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.mbp_pagination .page-item:hover {
  background-color: #f1fcfa;
}
.mbp_pagination .page-item:first-child {
  border: 1px solid #e9e9e9;
}
.mbp_pagination .page-item:last-child {
  border: 1px solid #222222;
}
.mbp_pagination .page-item.active .page-link {
  background-color: #5bbb7b;
  color: #ffffff;
}
.mbp_pagination .page-link {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: 40px;
}

/*	ProgressBar Styles  */
.progressbar-style1 {
  position: relative;
}
.progressbar-style1 .progressbar-bg {
  background-color: #f1fcfa;
  border-radius: 10px;
  height: 10px;
  width: 100%;
}
.progressbar-style1 .progressd-bar {
  background-color: #e1c03f;
  border-radius: 10px;
  position: absolute;
  height: 10px;
  top: 0;
}
.progressbar-style1 .progressd-bar span {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  right: 0;
  top: -25px;
  position: absolute;
}

.range-slider-style1,
.range-slider-style2 {
  max-width: 300px;
  position: relative;
  width: 100%;
}

span.ui-slider-handle.ui-corner-all.ui-state-default,
span.ui-slider-handle.ui-corner-all.ui-state-default:active,
span.ui-slider-handle.ui-corner-all.ui-state-default:focus,
span.ui-slider-handle.ui-corner-all.ui-state-default:hover,
span.ui-slider-handle.ui-corner-all.ui-state-default:focus-visible {
  border: 2px solid #5bbb7b;
  border-radius: 50%;
  height: 14px;
  margin-left: 0;
  outline: none;
  top: -6px;
  width: 14px;
}

.ui-slider-range.ui-corner-all.ui-widget-header {
  background-color: #5bbb7b;
  height: 4px;
}

.slider-range.ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
  background-color: #e9e9e9;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 4px;
}

input.amount {
  text-align: start;
}
input.amount,
input.amount2,
input.amount3,
input.amount4 {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-size: 14px;
  font-weight: 400;
  height: 45px;
  line-height: 22px;
  padding: 0 10px;
  width: 120px;
}
@media (max-width: 1199.98px) {
  input.amount,
  input.amount2,
  input.amount3,
  input.amount4 {
    max-width: 100px;
  }
}
input::placeholder {
  color: var(--headings-color);
}
input:focus-visible {
  border: none;
  outline: none;
}

#slider-range-value1,
#slider-range-value2 {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0em;
  padding: 5px;
}

#slider-range-value1,
#slider-range-value3 {
  float: left;
}

#slider-range-value2 {
  float: right;
}

.slider-labels {
  margin-top: 15px;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-handle .noUi-handle {
  z-index: 10;
}
.noUi-handle .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-horizontal .noUi-handle {
  background-color: #ffffff;
  border: 2px solid #5bbb7b;
  border-radius: 50%;
  height: 10px;
  left: 0;
  top: -7px;
  width: 10px;
}

.noUi-background {
  background-color: #f7f7f7;
  border-radius: 30px;
  height: 3px;
}

.noUi-connect {
  background-color: #5bbb7b;
  border-radius: 30px;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}
.noUi-handle:active {
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
}

/*	Shop Styles  */
.shop-item {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 10px 30px 20px;
  position: relative;
  -webkit-transition: all 0.3s ease 0s ease;
  -moz-transition: all 0.3s ease 0s ease;
  -ms-transition: all 0.3s ease 0s ease;
  -o-transition: all 0.3s ease 0s ease;
  transition: all 0.3s ease 0s ease;
}
.shop-item:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

/*	Shop Single Page	*/
.shop-single .nav-link {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 0;
}
.shop-single .nav-link.active {
  background-color: transparent;
  border: 1px solid #222222;
}
.shop-single .shop-single-item {
  max-width: 643px;
}

.shop-single-content .price {
  font-weight: 700;
}

/*	Cart Page	*/
.cart-img {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  height: 100px;
  line-height: 100px;
  margin-right: 22px;
  text-align: center;
  width: 100px;
}

.shopping_cart_table thead {
  height: 70px;
  line-height: 70px;
}
.shopping_cart_table th {
  background-color: #f1fcfa;
  padding: 0;
}
.shopping_cart_table .table_body {
  padding-left: 30px;
}
.shopping_cart_table .table_body td {
  border-bottom: 1px solid #e9e9e9;
  padding: 30px 0;
  vertical-align: middle;
}

.shopping_cart_table .coupon_input {
  width: 270px;
}
@media (max-width: 1199.98px) {
  .shopping_cart_table .coupon_input {
    width: auto;
  }
}

.quantity-arrow-minus,
.quantity-arrow-plus {
  background-color: transparent;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: var(--headings-color);
  font-size: 10px;
  height: 25px;
  line-height: 20px;
  left: 10px;
  outline: none;
  position: absolute;
  text-align: center;
  width: 25px;
  top: 15px;
}

.quantity-arrow-plus {
  left: auto;
  right: 10px;
}

.quantity-num {
  border: none;
  border-radius: 4px 0 0 4px;
  font-size: 15px;
  height: 56px;
  max-width: 140px;
  outline: none;
  padding: 0;
  text-align: center;
  width: 100%;
}

.quantity-block {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  height: 60px;
  position: relative;
  vertical-align: middle;
  width: 140px;
}

.cart-delete {
  background-color: #ffede8;
  border-radius: 4px;
  color: #1f4b3f;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

/*	Shop Order  */
.order_complete_message {
  position: relative;
}
.order_complete_message .icon {
  border-radius: 50%;
  font-size: 30px;
  height: 80px;
  line-height: 80px;
  margin: 0 auto 20px;
  width: 80px;
}
.order_complete_message .title {
  font-size: 28px;
}

.shop_order_box {
  position: relative;
}
.shop_order_box .order_list_raw {
  background-color: #ffede8;
  border: 2px dashed #222222;
  border-radius: 8px;
  margin-bottom: 60px;
  padding: 35px 80px;
}
@media (max-width: 575.98px) {
  .shop_order_box .order_list_raw {
    padding: 40px 30px;
  }
}
.shop_order_box .order_details {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 50px 60px 70px;
}
@media (max-width: 575.98px) {
  .shop_order_box .order_details {
    padding: 40px 30px;
  }
}

.checkout_form
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}
.checkout_form
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn,
.checkout_form
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  )
  .btn-light {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
}

.checkout_coupon {
  position: relative;
}

.order_sidebar_widget {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  padding: 30px;
  position: relative;
}
.order_sidebar_widget .title {
  margin-bottom: 28px;
}

.payment_widget {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 30px;
  position: relative;
}
.payment_widget .title {
  margin-bottom: 28px;
}
.payment_widget .form-check-label {
  font-weight: 500;
}

/*	Shop Details Pages Styles */
.service-single-sldier {
  position: relative;
}
.service-single-sldier:after {
  background-color: #404040;
  border-radius: 4px;
  bottom: 130px;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 767.98px) {
  .service-single-sldier:after {
    bottom: 0;
  }
}
.service-single-sldier.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: 30px;
}
@media (max-width: 767.98px) {
  .service-single-sldier.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    left: 10px;
    width: 35px;
  }
}
.service-single-sldier.vam_nav_style.owl-theme .owl-nav button.owl-next {
  right: 30px;
}
@media (max-width: 767.98px) {
  .service-single-sldier.vam_nav_style.owl-theme .owl-nav button.owl-next {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    right: 10px;
    width: 35px;
  }
}
.service-single-sldier button.owl-dot {
  border-radius: 4px;
  height: 114px;
  margin-right: 10px;
  width: 150px;
}
.service-single-sldier button.owl-dot:first-child {
  background-image: url(./images/listings/service-details-s-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .service-single-sldier button.owl-dot:first-child {
    display: none;
  }
}
.service-single-sldier button.owl-dot:nth-child(2) {
  background-image: url(./images/listings/service-details-s-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .service-single-sldier button.owl-dot:nth-child(2) {
    display: none;
  }
}
.service-single-sldier button.owl-dot:nth-child(3) {
  background-image: url(./images/listings/service-details-s-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .service-single-sldier button.owl-dot:nth-child(3) {
    display: none;
  }
}

/*	Employee Single Styles */
.employee-single-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: 30px;
}
@media (max-width: 767.98px) {
  .employee-single-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    left: 10px;
    width: 35px;
  }
}
.employee-single-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
  right: 30px;
}
@media (max-width: 767.98px) {
  .employee-single-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
    font-size: 12px;
    height: 35px;
    line-height: 35px;
    right: 10px;
    width: 35px;
  }
}
.employee-single-slider button.owl-dot {
  border-radius: 4px;
  height: 114px;
  margin-right: 10px;
  width: 150px;
}
.employee-single-slider button.owl-dot:first-child {
  background-image: url(./images/about/employee-single-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .employee-single-slider button.owl-dot:first-child {
    display: none;
  }
}
.employee-single-slider button.owl-dot:nth-child(2) {
  background-image: url(./images/about/employee-single-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .employee-single-slider button.owl-dot:nth-child(2) {
    display: none;
  }
}
.employee-single-slider button.owl-dot:nth-child(3) {
  background-image: url(./images/about/employee-single-4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .employee-single-slider button.owl-dot:nth-child(3) {
    display: none;
  }
}

/*	Social Styles  */
.social-style1 i {
  color: #ffffff;
  border-radius: 50%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.social-style1 i:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.social-style1.light-style i {
  color: var(--headings-color);
}
.social-style1.light-style i:hover {
  background-color: var(--headings-color);
  color: #ffffff;
}
.social-style1.light-style2 i {
  color: var(--headings-color);
}
.social-style1.light-style2 i:hover {
  background-color: #f7f7f7;
  color: var(--headings-color);
}

.app-list .app-title,
.app-list i {
  color: rgba(255, 255, 255, 0.7);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.app-list:hover .app-title,
.app-list:hover i {
  color: #ffffff;
}
.app-list.light-style i {
  color: rgba(34, 34, 34, 0.7);
}
.app-list.light-style i:hover {
  background-color: var(--headings-color);
  color: #ffffff;
}
.app-list.light-style .app-title {
  color: rgba(34, 34, 34, 0.7);
}

/*	Sliders Styles  */
.thumbimg-countnumber-carousel .owl-dots {
  position: absolute;
  right: 340px;
  top: 300px;
  width: 60px;
}
@media (max-width: 1399.98px) {
  .thumbimg-countnumber-carousel .owl-dots {
    right: 50px;
  }
}
@media (max-width: 1199.98px) {
  .thumbimg-countnumber-carousel .owl-dots {
    bottom: 0;
    left: 30px;
    top: auto;
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .thumbimg-countnumber-carousel .owl-dots {
    display: none;
  }
}

.banner-wrapper .owl-dot {
  display: grid;
  position: relative;
  z-index: 991;
}
.banner-wrapper .owl-dot span {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border-radius: 50%;
  height: 50px;
  margin: 0 0 15px;
  opacity: 0.7;
  width: 50px;
  transition: all 0.4s ease;
}
.banner-wrapper .owl-dot:last-child {
  margin-bottom: 0;
}
.banner-wrapper .owl-dot.active span {
  opacity: 1;
  outline: 2px solid #ffffff;
  outline-offset: 5px;
}
.banner-wrapper .carousel-control-block {
  align-items: center;
  background-color: #ffffff;
  border-radius: 16px;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  max-width: 160px;
  padding: 15px 20px;
  position: absolute;
  right: 20px;
  width: 100%;
  z-index: 999;
}
.banner-wrapper .carousel-btn-block {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e9e9e9;
  padding-left: 20px;
}
.banner-wrapper .carousel-btn-block span {
  font-size: 30px;
  color: var(--headings-color);
  cursor: pointer;
  line-height: 0.6em;
}
.banner-wrapper .carousel-btn-block span + span {
  margin-top: 7px;
}
.banner-wrapper .slider-number-count {
  font-weight: 400;
  color: var(--headings-color);
  display: flex;
}
.banner-wrapper .sep {
  margin-left: 10px;
  margin-right: 10px;
}
.banner-wrapper .sep,
.banner-wrapper .current-number {
  color: #5bbb7b;
  font-weight: 600;
}

.nav_none.owl-theme.owl-carousel .owl-nav {
  display: none !important;
}

.dots_none.owl-theme.owl-carousel .owl-dots {
  display: none !important;
}

.navi_pagi_top_right.owl-theme .owl-dots,
.navi_pagi_bottom_left.owl-theme .owl-dots,
.navi_pagi_bottom_center.owl-theme .owl-dots {
  display: block;
  text-align: center;
  width: 100px;
}
.navi_pagi_top_right.owl-theme .owl-nav,
.navi_pagi_bottom_left.owl-theme .owl-nav,
.navi_pagi_bottom_center.owl-theme .owl-nav {
  display: block;
  width: 140px;
  position: absolute;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev {
  left: 0;
  right: auto;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-next,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 0;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev,
.navi_pagi_top_right.owl-theme .owl-nav .owl-next,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next {
  background-color: transparent;
  border: none;
  color: var(--headings-color);
  font-size: 14px;
  height: auto;
  line-height: initial;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev:hover,
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev:active,
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev:focus,
.navi_pagi_top_right.owl-theme .owl-nav .owl-next:hover,
.navi_pagi_top_right.owl-theme .owl-nav .owl-next:active,
.navi_pagi_top_right.owl-theme .owl-nav .owl-next:focus,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev:hover,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev:active,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev:focus,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next:hover,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next:active,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next:focus,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev:hover,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev:active,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev:focus,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next:hover,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next:active,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next:focus {
  background-color: transparent;
  color: var(--headings-color);
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev i,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev i,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev i {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-prev:hover i,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-prev:hover i,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-prev:hover i {
  transform: translateX(-5px) scale(1.25);
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-next i,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next i,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next i {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_top_right.owl-theme .owl-nav .owl-next:hover i,
.navi_pagi_bottom_left.owl-theme .owl-nav .owl-next:hover i,
.navi_pagi_bottom_center.owl-theme .owl-nav .owl-next:hover i {
  transform: translateX(5px) scale(1.25);
}
.navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:hover span,
.navi_pagi_top_right.owl-theme .owl-dots button.owl-dot.active span,
.navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:hover span,
.navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot.active span,
.navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:hover span,
.navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot.active span {
  background-color: #222222;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.navi_pagi_top_right.owl-theme .owl-dots button.owl-dot span,
.navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot span,
.navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot span {
  background-color: #c0c0c0;
  border-radius: 50%;
  height: 6px;
  position: relative;
  width: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 919px) {
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(4),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(5),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(7),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(8),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(9),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(10),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(11),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(12),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(13),
  .navi_pagi_top_right.owl-theme .owl-dots button.owl-dot:nth-child(14),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(4),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(5),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(7),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(8),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(9),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(10),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(11),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(12),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(13),
  .navi_pagi_bottom_left.owl-theme .owl-dots button.owl-dot:nth-child(14),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(4),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(5),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(7),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(8),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(9),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(10),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(11),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(12),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(13),
  .navi_pagi_bottom_center.owl-theme .owl-dots button.owl-dot:nth-child(14) {
    display: none;
  }
}

.navi_pagi_vertical_right.owl-theme .owl-dots {
  position: absolute;
  right: 30px;
  text-align: center;
  top: 40%;
  width: 20px;
}
@media (max-width: 767.98px) {
  .navi_pagi_vertical_right.owl-theme .owl-dots {
    bottom: 10px;
    height: 30px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: auto;
    width: 120px;
  }
}
.navi_pagi_vertical_right.owl-theme .owl-nav {
  bottom: 0;
  height: 20px;
  left: auto;
  position: absolute;
  right: -27px;
  top: 44%;
  transform: rotate(90deg);
  width: 140px;
}
@media (max-width: 767.98px) {
  .navi_pagi_vertical_right.owl-theme .owl-nav {
    bottom: 13px;
    left: 0;
    position: absolute;
    right: 0;
    top: auto;
    transform: inherit;
    width: 140px;
    margin: 0 auto;
  }
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev {
  left: 0;
  right: auto;
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 0;
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next {
  background-color: transparent;
  border: none;
  color: var(--headings-color);
  font-size: 14px;
  height: auto;
  line-height: initial;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: auto;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev:hover,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev:active,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev:focus,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next:hover,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next:active,
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next:focus {
  background-color: transparent;
  color: var(--headings-color);
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev i {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-prev:hover i {
  transform: translateX(-5px) scale(1.25);
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next i {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.navi_pagi_vertical_right.owl-theme .owl-nav .owl-next:hover i {
  transform: translateX(5px) scale(1.25);
}
.navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:hover span,
.navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot.active span {
  background-color: #222222;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot span {
  background-color: #c0c0c0;
  border-radius: 50%;
  height: 6px;
  position: relative;
  width: 6px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 919px) {
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(4),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(5),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(7),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(8),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(9),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(10),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(11),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(12),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(13),
  .navi_pagi_vertical_right.owl-theme .owl-dots button.owl-dot:nth-child(14) {
    display: none;
  }
}

.dots_nav_light.owl-theme .owl-dots button.owl-dot:hover span,
.dots_nav_light.owl-theme .owl-dots button.owl-dot.active span,
.dots_nav_light.owl-theme .owl-dots button.owl-dot:active span,
.dots_nav_light.owl-theme .owl-dots button.owl-dot:focus span,
.dots_nav_light.owl-theme .owl-dots button.owl-dot:visited span {
  background-color: #ffffff;
}
.dots_nav_light.owl-theme .owl-dots button.owl-dot span {
  background-color: rgba(255, 255, 255, 0.6);
}
.dots_nav_light.owl-theme .owl-nav .owl-prev,
.dots_nav_light.owl-theme .owl-nav .owl-next {
  color: #ffffff;
}
.dots_nav_light.owl-theme .owl-nav .owl-prev:hover i,
.dots_nav_light.owl-theme .owl-nav .owl-prev.active i,
.dots_nav_light.owl-theme .owl-nav .owl-prev:active i,
.dots_nav_light.owl-theme .owl-nav .owl-prev:focus i,
.dots_nav_light.owl-theme .owl-nav .owl-prev:visited i,
.dots_nav_light.owl-theme .owl-nav .owl-next:hover i,
.dots_nav_light.owl-theme .owl-nav .owl-next.active i,
.dots_nav_light.owl-theme .owl-nav .owl-next:active i,
.dots_nav_light.owl-theme .owl-nav .owl-next:focus i,
.dots_nav_light.owl-theme .owl-nav .owl-next:visited i {
  color: #ffffff;
}

.navi_pagi_bottom_center.owl-theme .owl-nav {
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 991.98px) {
  .navi_pagi_bottom_center.owl-theme .owl-nav {
    bottom: 20px;
  }
}
.navi_pagi_bottom_center.owl-theme .owl-dots {
  bottom: -20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
}
@media (max-width: 991.98px) {
  .navi_pagi_bottom_center.owl-theme .owl-dots {
    bottom: 0;
  }
}

.navi_pagi_bottom_left.owl-theme .owl-dots {
  bottom: -18px;
  left: 20px;
  margin: 0 auto;
  position: absolute;
}
@media (max-width: 991.98px) {
  .navi_pagi_bottom_left.owl-theme .owl-dots {
    position: relative;
  }
}
.navi_pagi_bottom_left.owl-theme .owl-nav {
  bottom: 0;
  position: absolute;
}
@media (max-width: 991.98px) {
  .navi_pagi_bottom_left.owl-theme .owl-nav {
    position: relative;
  }
}

.navi_pagi_top_right.owl-theme .owl-dots {
  position: absolute;
  right: 20px;
  top: -90px;
  width: 100px;
}
@media (max-width: 991.98px) {
  .navi_pagi_top_right.owl-theme .owl-dots {
    left: 20px;
    position: relative;
    right: 0;
    top: -7px;
  }
}
.navi_pagi_top_right.owl-theme .owl-nav {
  right: 0;
  top: -83px;
}
@media (max-width: 991.98px) {
  .navi_pagi_top_right.owl-theme .owl-nav {
    position: relative;
    top: 0;
  }
}

.vam_nav_style.owl-theme .owl-nav {
  display: block;
  margin-top: 0;
}
.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: -30px;
  right: auto;
}
@media (max-width: 579px) {
  .vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: -10px;
  }
}
.vam_nav_style.owl-theme .owl-nav button.owl-next {
  left: auto;
  right: -30px;
}
@media (max-width: 579px) {
  .vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: -10px;
  }
}
.vam_nav_style.owl-theme .owl-nav button.owl-prev,
.vam_nav_style.owl-theme .owl-nav button.owl-next {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  -moz-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  -o-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  border: none;
  border-radius: 50%;
  color: #051036;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 38%;
  width: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.vam_nav_style.owl-theme .owl-nav button.owl-prev:hover,
.vam_nav_style.owl-theme .owl-nav button.owl-prev:active,
.vam_nav_style.owl-theme .owl-nav button.owl-prev:focus,
.vam_nav_style.owl-theme .owl-nav button.owl-next:hover,
.vam_nav_style.owl-theme .owl-nav button.owl-next:active,
.vam_nav_style.owl-theme .owl-nav button.owl-next:focus {
  background-color: #5bbb7b;
  color: #ffffff;
}

.slider-outer-dib,
.testimonial-slider {
  position: relative;
}
.slider-outer-dib .owl-stage-outer,
.testimonial-slider .owl-stage-outer {
  display: inline-block;
}

.property-city-slider.style2.vam_nav_style.owl-theme .owl-nav button.owl-prev,
.property-city-slider.style2.vam_nav_style.owl-theme .owl-nav button.owl-next {
  top: 25%;
}

.feature-listing-slider .listing-style1 {
  margin-bottom: 60px;
}

.feature-listing-slider2.owl-theme .owl-nav .owl-prev,
.feature-listing-slider2.owl-theme .owl-nav .owl-next {
  top: 25%;
}

.home3-testimonial-slider {
  position: relative;
}
.home3-testimonial-slider.owl-theme .owl-nav {
  bottom: 20px;
  left: -165px;
  margin: 0 auto;
  position: absolute;
}
@media (max-width: 1199.98px) {
  .home3-testimonial-slider.owl-theme .owl-nav {
    left: 85px;
  }
}
@media (max-width: 991.98px) {
  .home3-testimonial-slider.owl-theme .owl-nav {
    left: 0;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .home3-testimonial-slider.owl-theme .owl-nav {
    bottom: 0;
  }
}
.home3-testimonial-slider.owl-theme .owl-dots {
  bottom: 0px;
  left: -175px;
}
@media (max-width: 1199.98px) {
  .home3-testimonial-slider.owl-theme .owl-dots {
    left: 75px;
  }
}
@media (max-width: 991.98px) {
  .home3-testimonial-slider.owl-theme .owl-dots {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .home3-testimonial-slider.owl-theme .owl-dots {
    bottom: -20px;
  }
}

.home6-listing-single-slider.owl-theme .owl-nav {
  bottom: 20px;
  left: 40px;
}
.home6-listing-single-slider.owl-theme .owl-dots {
  bottom: 2px;
  left: 50px;
}
@media (max-width: 991.98px) {
  .home6-listing-single-slider.owl-theme .owl-dots {
    position: absolute;
  }
}

.home8-property-slider
  .swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 1px;
  left: 20px;
  right: auto;
  width: 70%;
}
.home8-property-slider .swiper-pagination-progressbar-fill {
  height: 3px;
}

.swpr_paginations {
  max-width: 330px;
  position: relative;
  width: 100%;
}
.swpr_paginations .slideactive {
  position: absolute;
  left: 0;
  top: -3px;
}
.swpr_paginations .slidetotal {
  position: absolute;
  right: 0;
  top: -3px;
}

.main-banner-wrapper {
  overflow: hidden;
  position: relative;
}
.main-banner-wrapper .carousel-btn-block {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
}
@media (max-width: 1600px) {
  .main-banner-wrapper .carousel-btn-block {
    top: auto;
    bottom: 80px;
  }
}
@media (max-width: 575.98px) {
  .main-banner-wrapper .carousel-btn-block {
    display: none;
  }
}
.main-banner-wrapper .carousel-btn {
  background-color: transparent;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  width: 70px;
  z-index: 1;
  -webkit-box-shadow: 0px 10px 35px rgba(17, 33, 55, 0.1);
  -moz-box-shadow: 0px 10px 35px rgba(17, 33, 55, 0.1);
  -o-box-shadow: 0px 10px 35px rgba(17, 33, 55, 0.1);
  box-shadow: 0px 10px 35px rgba(17, 33, 55, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  vertical-align: middle;
}
.main-banner-wrapper .carousel-btn:hover {
  background-color: #ffffff;
  color: var(--headings-color);
  opacity: 1;
}
.main-banner-wrapper .carousel-btn.left-btn {
  position: absolute;
  left: 30px;
}
.main-banner-wrapper .carousel-btn.right-btn {
  left: auto;
  position: absolute;
  right: 30px;
}
@media (max-width: 1600px) {
  .main-banner-wrapper .carousel-btn.right-btn {
    left: 100px;
  }
}
@media (max-width: 1199.98px) {
  .main-banner-wrapper .carousel-btn.right-btn {
    top: auto;
  }
}
.main-banner-wrapper.home10_style .carousel-btn.left-btn,
.main-banner-wrapper.home10_style .carousel-btn.right-btn {
  left: auto;
  right: 18%;
}
@media (max-width: 1199.98px) {
  .main-banner-wrapper.home10_style .carousel-btn.right-btn {
    top: auto;
    left: auto;
    right: 13%;
  }
}
@media (max-width: 991.98px) {
  .main-banner-wrapper.home10_style .carousel-btn.right-btn {
    right: 10%;
  }
}
@media (max-width: 767.98px) {
  .main-banner-wrapper.home10_style .carousel-btn.right-btn {
    right: 8%;
  }
}
@media (max-width: 575.98px) {
  .main-banner-wrapper.home10_style .carousel-btn.right-btn {
    right: 5px;
  }
}

.banner-style-one.home10_style .slide {
  height: 700px;
}
@media (max-width: 1199.98px) {
  .banner-style-one.home10_style .slide {
    height: 600px;
  }
}
.banner-style-one .slide {
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 860px;
}
@media (max-width: 1199.98px) {
  .banner-style-one .slide {
    height: 680px;
  }
}
.banner-style-one .sub-title {
  color: #ffffff;
  font-size: 36px;
  font-family: var(--title-font-family);
  line-height: 60px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media (max-width: 1199.98px) {
  .banner-style-one .sub-title {
    font-size: 48px;
    line-height: initial;
  }
}
@media (max-width: 991.98px) {
  .banner-style-one .sub-title {
    font-size: 36px;
  }
}
.banner-style-one .banner-title {
  color: #ffffff;
  font-size: 45px;
  font-family: var(--title-font-family);
  line-height: 60px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media (max-width: 1199.98px) {
  .banner-style-one .banner-title {
    font-size: 36px;
    line-height: initial;
  }
}
@media (max-width: 575.98px) {
  .banner-style-one .banner-title {
    font-size: 26px;
  }
}
.banner-style-one .banner-text {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.banner-style-one .banner-btn {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.banner-style-one .banner-content {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
.banner-style-one .active .banner-title,
.banner-style-one .active .sub-title,
.banner-style-one .active .banner-text,
.banner-style-one .active .banner-btn,
.banner-style-one .active .banner-content {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.banner-title {
  color: #ffffff;
  font-size: 45px;
  font-family: var(--title-font-family);
  line-height: 60px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@media (max-width: 1199.98px) {
  .banner-title {
    font-size: 36px;
    line-height: initial;
  }
}
@media (max-width: 575.98px) {
  .banner-title {
    font-size: 26px;
  }
}

@media (max-width: 767.98px) {
  .home1_style .banner-style-one .slide {
    height: 500px;
  }
}

.ps-v4-hero-slider.owl-carousel .owl-stage-outer {
  display: inline;
}
.ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev,
.ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
  top: 45%;
}
.ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: -100px;
}
@media (max-width: 1399.98px) {
  .ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: -30px;
  }
}
@media (max-width: 767.98px) {
  .ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: 0;
  }
}
.ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
  right: -100px;
}
@media (max-width: 1399.98px) {
  .ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: -30px;
  }
}
@media (max-width: 767.98px) {
  .ps-v4-hero-slider.vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: 0px;
  }
}
@media (max-width: 575.98px) {
  .ps-v4-hero-slider .owl-item img {
    height: 250px;
  }
}

.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-prev,
.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-next {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  top: 45%;
}
.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-prev:hover,
.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-next:hover {
  background-color: #ffffff;
  color: var(--headings-color);
}
.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: 100px;
}
@media (max-width: 1199.98px) {
  .ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: 30px;
  }
}
.ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-next {
  right: 100px;
}
@media (max-width: 1199.98px) {
  .ps-v4-hero-slider2.vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: 30px;
  }
}
@media (max-width: 575.98px) {
  .ps-v4-hero-slider2 .owl-item img {
    height: 200px;
  }
}

.ps-v6-slider.owl-theme .owl-dots .owl-dot:first-child {
  /* background-image: url(./images/listings/listing-single-6-1.jpg); */
  background-position: center;
  background-size: cover;
}
.ps-v6-slider.owl-theme .owl-dots .owl-dot:nth-child(2) {
  /* background-image: url(./images/listings/listing-single-6-2.jpg); */
  background-position: center;
  background-size: cover;
}
.ps-v6-slider.owl-theme .owl-dots .owl-dot:nth-child(3) {
  /* background-image: url(./images/listings/listing-single-6-3.jpg); */
  background-position: center;
  background-size: cover;
}
.ps-v6-slider.owl-theme .owl-dots .owl-dot:nth-child(4) {
  /* background-image: url(./images/listings/listing-single-6-4.jpg); */
  background-position: center;
  background-size: cover;
}
.ps-v6-slider button.owl-dot {
  background-color: aliceblue;
  border-radius: 4px;
  height: 83.33px;
  margin-bottom: 5px;
  margin-right: 10px;
  width: 90px;
}

.listing-thumbIn-slider.owl-theme .owl-nav {
  bottom: 27px;
  z-index: 1;
}
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-prev,
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-next {
  color: #ffffff;
}
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-prev:hover,
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-prev:focus,
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-next:hover,
.listing-thumbIn-slider.owl-theme .owl-nav button.owl-next:focus {
  color: #ffffff;
}
.listing-thumbIn-slider.owl-theme .owl-dots {
  bottom: 8px;
  z-index: 1;
}
.listing-thumbIn-slider.owl-theme .owl-dots button.owl-dot span {
  background-color: rgba(255, 255, 255, 0.6);
}
.listing-thumbIn-slider.owl-theme .owl-dots button.owl-dot:hover span,
.listing-thumbIn-slider.owl-theme .owl-dots button.owl-dot:focus span,
.listing-thumbIn-slider.owl-theme .owl-dots button.owl-dot.active span {
  background-color: #ffffff;
}

@media (max-width: 991.98px) {
  .testimonial-slider2.navi_pagi_bottom_center.owl-theme .owl-nav,
  .team-slider-home10.navi_pagi_bottom_center.owl-theme .owl-nav {
    bottom: 0px;
  }
  .testimonial-slider2.navi_pagi_bottom_center.owl-theme .owl-dots,
  .team-slider-home10.navi_pagi_bottom_center.owl-theme .owl-dots {
    bottom: -20px;
  }
}

@media (max-width: 360px) {
  .category-slider-home10 .iconbox-style1 {
    padding: 40px 20px 30px;
  }
}

.testimonial-slider-home8.navi_pagi_bottom_left.owl-theme .owl-dots {
  bottom: -20px;
  margin: initial;
}
@media (max-width: 991.98px) {
  .testimonial-slider-home8.navi_pagi_bottom_left.owl-theme .owl-dots {
    bottom: 10px;
  }
}

.testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-prev {
  left: -60px;
}
@media (max-width: 1600px) {
  .testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: -30px;
  }
}
@media (max-width: 575.98px) {
  .testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-prev {
    left: 0;
    top: 30%;
  }
}
.testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-next {
  right: -60px;
}
@media (max-width: 1600px) {
  .testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: -30px;
  }
}
@media (max-width: 575.98px) {
  .testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-next {
    right: 0;
    top: 30%;
  }
}
.testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-prev,
.testimonial-slider-home11.vam_nav_style.owl-theme .owl-nav button.owl-next {
  border: 1px solid #e9e9e9;
  box-shadow: none;
}

/*	Sidebar Styles  */
.mobile-filter-btn {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  border-radius: 60px;
  display: block;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 98px;
}
.mobile-filter-btn span {
  padding-right: 8px;
}
@media (max-width: 575.98px) {
  .mobile-filter-btn {
    top: 35px;
  }
}
.mobile-filter-btn.map-page {
  border-radius: 4px;
  height: 55px;
  line-height: 55px;
  padding-left: 50px;
  right: auto;
  top: 30px;
  width: 169px;
  z-index: 1;
}
.mobile-filter-btn.map-page span {
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  height: 55px;
  left: 0;
  padding: 0;
  line-height: 55px;
  position: absolute;
  width: 55px;
}

.blog-sidebar,
.shop-sidebar {
  max-width: 370px;
}

.widget-wrapper {
  margin-bottom: 30px;
  position: relative;
}
.widget-wrapper .list-title {
  margin-bottom: 15px;
}
.widget-wrapper .form-check-label,
.widget-wrapper .custom_checkbox {
  font-size: 15px;
}
.widget-wrapper .custom_checkbox {
  line-height: 35px;
}

.search_area {
  position: relative;
}
.search_area .form-control {
  padding-left: 20px;
}
.search_area input {
  font-size: 15px;
}
.search_area input::placehoder {
  color: var(--headings-color);
}
.search_area label {
  color: var(--headings-color);
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 15px;
}
.search_area.dashboard-style .form-control {
  font-size: 14px;
  width: 600px;
}
@media (max-width: 1399.98px) {
  .search_area.dashboard-style .form-control {
    width: 500px;
  }
}
.search_area.dashboard-style .form-control::placeholder {
  color: #222222;
}

.room-list {
  display: flex;
}
@media (max-width: 1199.98px) {
  .room-list {
    display: inherit;
  }
}
.room-list a {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  height: 45px;
  line-height: 45px;
  margin-right: -1px;
  text-align: center;
  width: 47px;
}
@media (max-width: 1199.98px) {
  .room-list a {
    display: inline-block;
    margin-bottom: 10px;
  }
}
.room-list a:first-child {
  border: 2px solid #222222;
  border-radius: 12px 0 0 12px;
  margin-right: 0;
}
.room-list a:last-child {
  border-radius: 0 12px 12px 0;
  margin-right: 0;
}

.location-area {
  position: relative;
}
.location-area .btn {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  height: 50px;
  line-height: 35px;
  padding-left: 15px;
  background-color: #ffffff;
  height: 50px;
}
.location-area .btn:before,
.location-area .btn:after {
  display: none;
}

.space-area .form-control {
  max-width: 130px;
  height: 50px;
}

.feature-button {
  align-items: center;
  display: flex;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  position: relative;
}
.feature-button span {
  background-color: #f7f7f7;
  border-radius: 50%;
  color: var(--headings-color);
  height: 40px;
  line-height: 45px;
  margin-right: 10px;
  text-align: center;
  width: 40px;
}

.reset-button {
  align-items: center;
  display: flex;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.reset-button span {
  display: flex;
  font-size: 16px;
  padding-right: 10px;
  line-height: initial;
}

.dropdown-lists {
  position: relative;
}
.dropdown-lists .dropdown-toggle:after {
  display: none;
}
.dropdown-lists .open-btn {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  cursor: pointer;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  font-weight: 500;
  letter-spacing: 0em;
  padding: 7px 20px;
}
.dropdown-lists .open-btn:hover {
  background-color: #f1fcfa;
}
.dropdown-lists .drop_content,
.dropdown-lists .drop_content2,
.dropdown-lists .drop_content3,
.dropdown-lists .drop_content4 {
  background-color: #ffffff;
  border-top: 1px solid #e9e9e9;
  border-radius: 8px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  display: none;
  left: 0px;
  padding: 30px;
  position: absolute;
  top: 50px;
  width: 300px;
  z-index: 9;
}
.dropdown-lists .drop_content:before,
.dropdown-lists .drop_content2:before,
.dropdown-lists .drop_content3:before,
.dropdown-lists .drop_content4:before {
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  content: "";
  height: 0;
  left: 30px;
  position: absolute;
  top: -5px;
  width: 0;
}
.dropdown-lists .dropdown-menu {
  padding: 30px;
  width: 300px;
}
.dropdown-lists .drop_content3,
.dropdown-lists .drop_content4 {
  width: 330px;
}

.agent-page-meta .form-control {
  border-radius: 40px;
  height: 44px;
  max-width: 300px;
  width: 100%;
}
.agent-page-meta .open-btn {
  border: 1px solid #e9e9e9;
  box-shadow: none;
  padding: 8px 20px;
}

.list-news-style .news-img {
  border-radius: 8px;
  overflow: hidden;
}
.list-news-style .title span {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.list-news-style .list-meta a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}
.list-news-style .new-text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
}

.sidebar-widget {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  padding: 30px;
  position: relative;
}

.search_area input::placeholder {
  font-size: 14px;
}

.category-list a {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin-bottom: 20px;
}
.category-list a:last-child {
  margin-bottom: 0;
}

.tag-list a {
  background-color: #ffede8;
  border-radius: 60px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 6px 20px;
}
.tag-list a:hover {
  color: #1f4b3f;
}

.sidebar-accordion .card,
.list-sidebar-style1 .card {
  border: none;
  border-bottom: 1px solid #e9e9e9;
  margin-top: 10px;
}
.sidebar-accordion .card:last-child,
.list-sidebar-style1 .card:last-child {
  border-bottom: none;
}
.sidebar-accordion .card-header,
.list-sidebar-style1 .card-header {
  background-color: transparent;
  border: none;
  padding: 0;
}
.sidebar-accordion .card-header.active:before,
.list-sidebar-style1 .card-header.active:before {
  content: "";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar-accordion .card-header:before,
.list-sidebar-style1 .card-header:before {
  color: #222222;
  content: "";
  font-family: "Font Awesome 6 Pro";
  font-size: 14px;
  font-weight: 900;
  float: right;
  line-height: 35px;
  margin-top: 0;
}
.sidebar-accordion .btn-link,
.list-sidebar-style1 .btn-link {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.share-save-widget .icon {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
}

.price-widget {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  margin-bottom: 30px;
  padding: 15px 30px 30px;
  position: relative;
}
.price-widget .price {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 10px;
}
.price-widget .widget-title {
  font-size: 28px;
  font-weight: 700;
}

.widget-mt-minus {
  margin-top: -185px;
}
@media (max-width: 1199.98px) {
  .widget-mt-minus {
    margin-top: 0;
  }
}

.educational-quality {
  padding-left: 50px;
  position: relative;
}
.educational-quality .m-circle {
  background-color: #f1fcfa;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 30px;
}
.educational-quality .m-circle:before {
  border-left: 1px dashed #5bbb7b;
  bottom: 0;
  content: "";
  height: 140px;
  position: absolute;
  left: 15px;
  top: 40px;
  width: 1px;
}
.educational-quality .tag {
  background-color: #ffede8;
  border-radius: 60px;
  display: inline-block;
  font-size: 13px;
  padding: 5px 20px;
  color: var(--headings-color);
}

.project-attach {
  background-color: rgba(91, 187, 123, 0.1);
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 20px 20px 1px 20px;
  position: relative;
}
.project-attach .icon {
  color: rgba(91, 187, 123, 0.15);
  bottom: 10px;
  font-size: 30px;
  position: absolute;
  right: 10px;
}

/*	Table Styles  */
.table-style1 {
  position: relative;
}
.table-style1 .table thead tr {
  background-color: #f1fcfa;
  overflow: hidden;
}
.table-style1 .table th {
  color: #222222;
  line-height: 55px;
  padding-left: 27px;
}
.table-style1 .table th:first-child {
  border-radius: 8px 0 0 8px;
}
.table-style1 .table th:last-child {
  border-radius: 0 8px 8px 0;
}
.table-style1 .table td {
  border-bottom: 1px solid #e9e9e9;
  font-family: var(--title-font-family);
  line-height: 52px;
  padding-left: 27px;
}

.table-style2 {
  position: relative;
}
.table-style2 .table .t-head th {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 30px;
  padding-top: 30px;
  vertical-align: baseline;
}
.table-style2 .table .t-head small {
  font-family: var(--title-font-family);
  font-weight: 400;
  font-size: 15px;
  color: #697488;
}
.table-style2 .t-body th {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0em;
  padding: 30px;
}
.table-style2 .t-body tr {
  border-top: 1px solid #e9e9e9;
}
.table-style2 .t-body td {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding-bottom: 30px;
  padding-top: 30px;
}
.table-style2 .t-body .check_circle {
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font-size: 8px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 25px;
}

.table-style2 table.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-style3.table {
  border-spacing: inherit;
  border-collapse: separate;
}
.table-style3 .t-head {
  background-color: #f1fcfa;
  border-radius: 4px;
}
.table-style3 .t-head th {
  border: 0;
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 17px;
  padding: 20px 30px;
}
.table-style3 .t-body th {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 15px;
  padding: 30px;
  vertical-align: middle;
}
.table-style3 .t-body td {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
  padding: 30px;
  vertical-align: middle;
}
.table-style3 .t-body tr:last-child {
  border-color: transparent;
}
.table-style3 .icon {
  background-color: #ffede8;
  border-radius: 4px;
  color: #1f4b3f;
  display: block;
  height: 40px;
  line-height: 45px;
  text-align: center;
  width: 40px;
}
.table-style3.at-savesearch .t-body tr:last-child {
  border-color: #e9e9e9;
}

.invoice_table {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  border-radius: 8px;
  position: relative;
}
.invoice_table .wrapper {
  padding: 120px 100px;
}
@media (max-width: 991.98px) {
  .invoice_table .wrapper {
    padding: 60px 30px;
  }
}
.invoice_table .tblh_row {
  background-color: #f1fcfa;
  border-radius: 8px;
}
.invoice_table .tblh_row th {
  color: var(--headings-color);
}
.invoice_table .tbleh_title,
.invoice_table .tbl_title,
.invoice_table .tblpr_title,
.invoice_table .tblp_title {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-top: 25px;
}
.invoice_table .tbl_title,
.invoice_table .tblpr_title {
  font-size: 15px;
  font-weight: 400;
}

.invoice_footer {
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #e9e9e9;
  padding: 45px 0 40px;
}

/*	Essential utility classes  */
.ff-heading {
  font-family: var(--title-font-family);
}

.text-white {
  color: #ffffff;
}

.bgc-white {
  background-color: #ffffff;
}

.hover-bgc-color {
  background-color: #f0efec;
}

.bgc-transparent {
  background-color: transparent;
}

.text-gray {
  color: #bebdbd;
}

.text-gray-2 {
  color: #f0efec;
}

.bgc-gray-3 {
  background-color: #f6f8fa;
}

.bgc-gray {
  background-color: #bebdbd;
}

.dark-color,
.heading-color,
.title-color {
  color: var(--headings-color);
}

.dark-color2 {
  color: #140342;
}

.bgc-dark {
  background-color: var(--headings-color);
}

.body-light-color {
  color: #697488;
}

.text-thm {
  color: #577799;
}

.text-thm2 {
  color: #48576e;
}

.text-thm3 {
  color: #f1fcfa;
}

.text-thm4 {
  color: #ffede8;
}

.text-thm5 {
  color: #fff8f6;
}

.text-thm6 {
  color: #443297;
}

.bgc-thm {
  background-color: #5bbb7b;
}

.bgc-thm2 {
  background-color: #48576e;
}

.bgc-thm3 {
  background-color: #f1fcfa;
}

.bgc-thm4 {
  background-color: #ffede8;
}

.bgc-thm5 {
  background-color: #fff8f6;
}

.bgc-thm7 {
  background-color: #e0f1e0;
}

.bgc-f7 {
  background-color: #f7f7f7;
}

.bgc-71 {
  background-color: #697488;
}

.text-thm3 {
  color: #5bbb7b;
}

.bgc-thm-light {
  background-color: rgba(91, 187, 123, 0.05);
}

.bgc-thm3-light {
  background-color: rgba(91, 187, 123, 0.15);
}

.review-color {
  color: #e1c03f;
}

.review-color2 {
  color: #c4c640;
}

.review-color3 {
  color: #c8973b;
}

.bgc-review-color2 {
  background-color: #c8973b;
}

.text-light-yellow {
  color: #fbf7ed;
}

.bgc-light-yellow {
  background-color: #fbf7ed;
}

.text-red {
  color: #e8543e;
}

.bgc-red {
  background-color: #e8543e;
}

/*== Fonts Size, Font Weights, Display & Position ==*/
.fz0 {
  font-size: 0px;
}

.fz6 {
  font-size: 6px;
}

.fz7 {
  font-size: 7px;
}

.fz8 {
  font-size: 8px;
}

.fz9 {
  font-size: 9px;
}

.fz10 {
  font-size: 10px;
}

.fz11 {
  font-size: 11px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz14 {
  font-size: 14px;
}

.fz15 {
  font-size: 15px;
}

.fz16 {
  font-size: 16px;
}

.fz17 {
  font-size: 17px;
}

.fz18 {
  font-size: 18px;
}

.fz19 {
  font-size: 19px;
}

.fz20 {
  font-size: 20px;
}

.fz24 {
  font-size: 24px;
}

.fz26 {
  font-size: 26px;
}

.fz28 {
  font-size: 28px;
}

.fz30 {
  font-size: 30px;
}

.fz40 {
  font-size: 40px;
}

.fz45 {
  font-size: 45px;
}

.fz48 {
  font-size: 48px;
}

.fz50 {
  font-size: 50px;
}

.fz55 {
  font-size: 55px;
}

.fz60 {
  font-size: 60px;
}

.fz72 {
  font-size: 72px;
}

.fz100 {
  font-size: 100px;
}

.lh0 {
  line-height: 0px;
}

.lh30 {
  line-height: 30px;
}

.h510 {
  height: 510px;
}

.h550 {
  height: 550px;
}

.h580 {
  height: 580px;
}

.h600 {
  height: 600px;
}

.h250 {
  height: 250px;
}

.wa {
  width: auto;
}

.w60 {
  width: 60px;
}

.w90 {
  width: 90px;
}

.w100 {
  width: 100%;
}

.maxw100 {
  max-width: 100%;
}

.maxw140 {
  max-width: 140px;
}

.maxw1400 {
  max-width: 1400px;
}

.maxw1600 {
  max-width: 1600px;
}

.maxw1700 {
  max-width: 1700px;
}

.maxw1800 {
  max-width: 1800px;
}

.maxw1850 {
  max-width: 1850px;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.fwn {
  font-weight: normal;
}

.fwb {
  font-weight: bold;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
}

.dif {
  display: inline-flex;
}

.df {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.dfr {
  display: flow-root;
}

.dn {
  display: none;
}

.ovh {
  overflow: hidden;
}

.ovv {
  overflow: visible;
}

.posa {
  position: absolute;
}

.posr {
  position: relative;
}

.vam {
  vertical-align: middle;
}

.zi0 {
  z-index: 0;
}

.zi1 {
  z-index: 1;
}

.zi2 {
  z-index: 2;
}

.zi9 {
  z-index: 9;
}

.zi-1 {
  z-index: -1;
}

.curp {
  cursor: pointer;
}

.bdr1 {
  border: 1px solid #e9e9e9;
}

.bdr2 {
  border: 2px solid #222222;
}

.bdrb1 {
  border-bottom: 1px solid #e9e9e9;
}

.bb-white-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.br-white-light {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.bdrb2-dark {
  border-bottom: 2px solid #222222;
}

.bdrt1 {
  border-top: 1px solid #e9e9e9;
}

.bdrl1 {
  border-left: 1px solid #e9e9e9;
}

.bdrl-eunry {
  border-left: 1px solid #d5aca1;
}

.bdrr1 {
  border-right: 1px solid #e9e9e9;
}

.border-title-color {
  border: 1px solid #222222;
}

.bbn {
  border-bottom: none;
}

.border-none,
.no-border {
  border: none;
}

.white-bdrt1 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.gray-bdrb1 {
  border-bottom: 1px solid #ecedf2;
}

.gray-bdrt1 {
  border-top: 1px solid #ecedf2;
}

.bdrs0 {
  border-radius: 0;
}

.bdrs4 {
  border-radius: 4px;
}

.bdrs6 {
  border-radius: 6px;
}

.bdrs8 {
  border-radius: 8px;
}

.bdrs12 {
  border-radius: 12px;
}

.bdrs16 {
  border-radius: 16px;
}

.bdrs24 {
  border-radius: 24px;
}

.bdrs20 {
  border-radius: 20px;
}

.bdrs32 {
  border-radius: 32px;
}

.bdrs50 {
  border-radius: 50px;
}

.bdrs60 {
  border-radius: 60px;
}

.bdrs90 {
  border-radius: 90px;
}

.bdrs12-right-y {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.before-none:before {
  display: none;
}

.after-none:after {
  display: none;
}

.wa {
  width: auto !important;
}

.text-white-light {
  color: rgba(255, 255, 255, 0.7);
}

.text-dark-light {
  color: rgba(34, 34, 34, 0.7);
}

.text {
  color: var(--headings-color);
  font-family: var(--title-font-family);
  font-weight: 400;
}

.default-box-shadow1 {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.hover-default-box-shadow1:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -moz-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  -o-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.default-box-shadow2 {
  -webkit-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -moz-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  -o-box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
  box-shadow: 0px 5px 20px rgba(91, 187, 123, 0.15);
}

.default-box-shadow3 {
  -webkit-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -moz-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  -o-box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
  box-shadow: 0px 25px 70px rgba(1, 33, 58, 0.07);
}

.default-box-shadow4 {
  -webkit-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -moz-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  -o-box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
  box-shadow: 10px 10px 40px rgba(34, 34, 34, 0.05);
}

.default-box-shadow5 {
  -webkit-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  -moz-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  -o-box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
  box-shadow: 0px 10px 35px rgba(5, 16, 54, 0.1);
}

.default-box-shadow6 {
  -webkit-box-shadow: 10px 10px 0px #ffede8;
  -moz-box-shadow: 10px 10px 0px #ffede8;
  -o-box-shadow: 10px 10px 0px #ffede8;
  box-shadow: 10px 10px 0px #ffede8;
}

.default-box-shadow7 {
  -webkit-box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  -moz-box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  -o-box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
  box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);
}

.box-shadow-none {
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .bdrrn-sm {
    border-right: none;
  }

  .bb1-md {
    border-bottom: 1px solid #e9e9e9;
  }

  .wa-md {
    width: auto;
  }
}
@media (max-width: 767.98px) {
  .slider-dib-sm.owl-theme .owl-stage-outer {
    display: inline-block;
  }

  .bb1-sm {
    border-bottom: 1px solid #e9e9e9;
  }
}
hr {
  background-color: #e9e9e9;
}

@media (max-width: 1199.98px) {
  h1 {
    font-size: 36px;
  }
}

/*# sourceMappingURL=style.css.map */
/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (tablets, 768px and up) */
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
/* Large devices (large desktops, 1200px and up) */

/* Extra small devices (phones, less than 768px) */
/* No media query since this is the default in Bootstrap */

@media only screen and (max-width: 1700px) {
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1366px) {
}

/*  (min-width:1280px)+++ */
@media only screen and (max-width: 1280px) {
}

/*  (min-width:1200px)+++ */
@media only screen and (min-width: 1200px) {
}

/*  (max-width:1200px)+++ */
@media only screen and (max-width: 1200px) {
}

/* (min-width:992px) (max-width: 1199.98px) // Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1199.98px) {
  /* ExTra Class Start*/
}

@media only screen and (max-width: 992px) {
}

/* Medium devices (min-width:768px) (max-width: 991.98px) // (tablets, less than 992px) */
@media only screen and (max-width: 991px) {
  /* ExTra Class Start*/
}

@media only screen and (max-width: 768px) {
}

/* Small devices (min-width: 576px) (max-width: 767.98px) // (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  /* ExTra Class Start*/
}

@media only screen and (max-width: 630px) {
}

@media only screen and (max-width: 580px) {
}

/* Extra small devices (min-width:361px) (max-width: 575.98px) // (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
}

/* (min-width:361px) (max-width:520px) */
@media only screen and (max-width: 520px) {
}

/* (min-width:480px) (max-width:500px) */
@media only screen and (max-width: 500px) {
}

/* (min-width:361px) (max-width:479px) */
@media only screen and (max-width: 479px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 400px) {
  /* ExTra Class Start*/
}

/* (min-width:321px) (max-width:360px) */
@media only screen and (max-width: 360px) {
}

@media only screen and (max-width: 340px) {
}

@media only screen and (max-width: 320px) {
}

#menu {
  display: none;
  height: 100vh;
  width: 80%;
}

#menu ul li {
  margin: 14px 5px;
}

#menu.open {
  display: block;
}

.signUp_card:hover {
  background-color: #577799;
  transition: background-color 0.2s ease-in;
}

.signUp_card:hover h3 {
  color: white;
}

